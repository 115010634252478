import type { Server } from 'miragejs';

import type { AppSchema, ShortlistedEnquiry } from 'Mirage/types';
import { getCachedState, setCachedState } from 'Utilities/helpers';
import { isNotNullOrUndefined } from 'Utilities/helpers/isNotNullOrUndefined';
import { API_ROUTES } from 'Utilities/routes';

type RemoveShortlistedEnquiriesFromDBProps = { enquiryIds: number[]; schema: AppSchema };

export const mirageShortlistedEnquiriesStorageKey = 'mirage-shortlisted-enquiries';

const shortlistPaths = [
	API_ROUTES.SHORTLIST,
	API_ROUTES.UNSHORTLIST,
	API_ROUTES.SHORTLISTED_ENQUIRIES,
	API_ROUTES.UNSHORTLIST_ENQUIRIES,
];

export const getShortlistedEnquiriesFromSchema = ({
	enquiryIds,
	schema,
}: RemoveShortlistedEnquiriesFromDBProps): ShortlistedEnquiry[] => {
	const enquiries = enquiryIds?.map((enquiryId: number) => schema.findBy('shortlistedEnquiry', { enquiryId })?.attrs);
	return enquiries?.filter(isNotNullOrUndefined);
};

export const setShortlistedEnquiriesFromStorage = (server: Server): void => {
	const shortlistedEnquiriesFromStorage = getCachedState(mirageShortlistedEnquiriesStorageKey);

	if (!shortlistedEnquiriesFromStorage) {
		return;
	}

	server.db.shortlistedEnquiries.remove();
	server.db.shortlistedEnquiries.insert(shortlistedEnquiriesFromStorage);
};

export const removeShortlistedEnquiriesFromDB = ({
	enquiryIds,
	schema,
}: RemoveShortlistedEnquiriesFromDBProps): void => {
	const data = getShortlistedEnquiriesFromSchema({ enquiryIds, schema });
	data.forEach((enquiry: ShortlistedEnquiry) => schema.db.shortlistedEnquiries.remove(enquiry));
};

type HandleShortlistRequestsProps = { path: string; shortlistedEnquiries: ShortlistedEnquiry[]; verb: string };

const handleShortlistRequests = ({ path, shortlistedEnquiries, verb }: HandleShortlistRequestsProps) => {
	const isShortlistPath = shortlistPaths.some((shortlistPath) => path.includes(shortlistPath));

	if (verb !== 'POST' || !isShortlistPath) {
		return;
	}

	const data = shortlistedEnquiries.map(({ enquiryId, updatedAt }) => ({ enquiryId, updatedAt }));
	setCachedState(mirageShortlistedEnquiriesStorageKey, data);
};

const persistShortlistedEnquiriesToStorage = (server: Server) => {
	const mirageRequestHandler = server.pretender.handledRequest;

	server.pretender.handledRequest = (verb, path, request) => {
		const { shortlistedEnquiries } = server.db;
		handleShortlistRequests({ path, shortlistedEnquiries, verb });
		mirageRequestHandler(verb, path, request);
	};
};

export const initialiseShortlistedEnquiries = (server: Server): void => {
	persistShortlistedEnquiriesToStorage(server);
	setShortlistedEnquiriesFromStorage(server);
};
