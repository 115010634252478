import { useContext } from 'react';
import { type StoreApi, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { LISTINGS_TABLE_SALE_TYPES } from 'Services/dynamoDb/dynamoDb.const';
import type { AdditionalVehicleDetailsFromDynamoDb } from 'Services/dynamoDb/dynamoDb.types';
import { createGenericStoreWithDevtools } from 'Stores/store';
import { AdditionalVehicleDetailsContext } from 'Stores/StoresContext/StoresContext';
import { isDev } from 'Utilities/serverUtils';

import type { AdditionalVehicleDetailsStore } from './AdditionalVehicleDetailsStore.types';

const createAdditionalVehicleDetailsStore = (
	initialAdditionalVehicleDetails: AdditionalVehicleDetailsFromDynamoDb = {},
) =>
	devtools<AdditionalVehicleDetailsStore>(
		(set, get) => ({
			additionalVehicleDetails: initialAdditionalVehicleDetails,
			getBuyItNowAdditionalVehicleDetailsById: (id) =>
				get().additionalVehicleDetails[LISTINGS_TABLE_SALE_TYPES.buyItNow]?.[id],
			getLiveSaleAdditionalVehicleDetailsById: (id) =>
				get().additionalVehicleDetails[LISTINGS_TABLE_SALE_TYPES.liveSale]?.[id],
			setAdditionalVehicleDetails: (additionalVehicleDetails: AdditionalVehicleDetailsFromDynamoDb) => {
				const current = get().additionalVehicleDetails;

				if (current !== additionalVehicleDetails) {
					set({ additionalVehicleDetails }, false, {
						additionalVehicleDetails,
						type: 'setAdditionalVehicleDetails',
					});
				}
			},
		}),
		{ enabled: isDev, name: 'AdditionalVehicleDetailsStore' },
	);

export const createAdditionalVehicleDetailsStoreWithInitial = (
	initialAdditionalVehicleDetails?: AdditionalVehicleDetailsFromDynamoDb,
): StoreApi<AdditionalVehicleDetailsStore> =>
	createGenericStoreWithDevtools(createAdditionalVehicleDetailsStore(initialAdditionalVehicleDetails));

export const useAdditionalVehicleDetailsStore = <T>(
	selector: (state: AdditionalVehicleDetailsStore) => T,
	equalityFn?: () => boolean,
): T => {
	const store = useContext(AdditionalVehicleDetailsContext);

	if (!store) {
		throw new Error('Missing AdditionalVehicleDetailsContext.Provider in the tree');
	}

	return useStore(store, selector, equalityFn);
};

export const useAdditionalVehicleDetails = () =>
	useAdditionalVehicleDetailsStore((store) => store.additionalVehicleDetails);

export const useGetBuyItNowAdditionalVehicleDetailsById = (id: string | number) =>
	useAdditionalVehicleDetailsStore((store) => store.getBuyItNowAdditionalVehicleDetailsById(id));

export const useGetLiveSaleAdditionalVehicleDetailsById = (id: string | number) =>
	useAdditionalVehicleDetailsStore((store) => store.getLiveSaleAdditionalVehicleDetailsById(id));

export const useSetAdditionalVehicleDetails = () =>
	useAdditionalVehicleDetailsStore((store) => store.setAdditionalVehicleDetails);
