/* eslint-disable max-classes-per-file */

export function handleAggregatedError(message: string, errors: unknown[]): Error {
	// Handle empty errors array
	if (!Array.isArray(errors)) {
		throw new TypeError('Errors parameter must be an array');
	}

	if (errors.length === 0) {
		const error: ErrorWithDetail = new Error(message);
		error.detail = [];
		return error;
	}

	const errorList = errors
		.filter((e) => e)
		.map((err) => {
			// If it's already an Error instance, preserve it
			if (err instanceof Error) {
				return err;
			}

			let error: ErrorWithDetail;

			if (typeof err === 'string') {
				// Handle string errors
				error = new Error(err);
			} else if (typeof err === 'object') {
				// Handle object-like errors
				const errorLike = err as ErrorLike;
				const errorMessage = typeof errorLike.message === 'string' ? errorLike.message : 'Unknown error';
				error = new Error(errorMessage);
			} else {
				// Handle other types (number, boolean, etc.)
				error = new Error(`Non-object error: ${String(err)}`);
			}

			error.detail = err;
			return error;
		});

	if (typeof AggregateError !== 'undefined') {
		return new AggregateError(errorList, message);
	}

	const error: ErrorWithDetail = new Error(message);
	error.detail = errorList;

	return error;
}

export class DealerError extends Error {
	constructor(functionName: string) {
		super();
		// Ensure the name of this error is the same as the class name
		this.name = this.constructor.name;
		this.message = `There was an error with ${functionName}`;
		// This clips the constructor invocation from the stack trace.
		// It's not absolutely essential, but it does make the stack trace a little nicer.
		Error?.captureStackTrace?.(this, this.constructor);
	}
}
/* DATA PROVIDER */
export class ComposerClientError extends DealerError {}
export class DealerCoreClientError extends DealerError {}
export class BiddingClientError extends DealerError {}
export class TransactionCoreClientError extends DealerError {}
export class VehicleCoreClientError extends DealerError {}
export class PaymentsCoreClientError extends DealerError {}
export class PDFClientError extends DealerError {}
export class ReportingInsightsClientError extends DealerError {}
export class DealershipPlatformError extends DealerError {}
export class OtpGeneratorError extends DealerError {}
export class FetchVehicleListError extends DealerError {}
export class MapFilesError extends DealerError {}
export class ServiceHistoryError extends DealerError {}

/* MOTORWAY SERVICE */
export class WithdrawalError extends DealerError {}
export class KycError extends DealerError {}
export class PaymentOptionsError extends DealerError {}
export class PaymentsProcessingError extends DealerError {}
export class PostSaleExperienceError extends DealerError {}
export class RelistDetailsError extends DealerError {}

/* THIRD PARTY SERVICE */
export class OptimizelyError extends DealerError {}
