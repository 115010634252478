import { isomorphicApiV2 } from 'Services/motorway/apiClient';
import { getAdditionalVehicleDetailsFromDynamoDb } from 'Services/motorway/services/additonalVehicleDetails/additionalVehicleDetails';
import { resolveParams } from 'Services/services.helpers';
import { API_ROUTES } from 'Utilities/routes';

import axios from '../axios';
import type { AdditionalVehicleDetailsFromDynamoDb, ListingsTableSaleType } from '../dynamoDb/dynamoDb.types';

export const getAdditionalVehicleDetails = async (
	query?: Record<'saleType', ListingsTableSaleType>,
): Promise<AdditionalVehicleDetailsFromDynamoDb> =>
	isomorphicApiV2(undefined, {
		browser: async () => axios.get(`${API_ROUTES.GET_ADDITIONAL_VEHICLE_DETAILS}${resolveParams(query)}`),
		server: async () => getAdditionalVehicleDetailsFromDynamoDb(query?.saleType),
	}).then((data) => {
		return data ?? {};
	});
