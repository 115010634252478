import camelCase from 'lodash/camelCase';
import { trait } from 'miragejs';

import vehicleStatuses from '../../__tests__/states.mock';

export const convertStatusesToTraits = (statuses) =>
	Object.entries(statuses).reduce((traits, [key, value]) => {
		traits[`${camelCase(`in_${key}`)}`] = trait({
			state: value,
		});
		return traits;
	}, {});

export const vehicleStatusTraits = convertStatusesToTraits(vehicleStatuses);
