import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import { AccountSlugs } from 'Types/account';

const { ACCOUNT } = ANALYTICS_CATEGORIES;

export const triggerCompletePurchasesMarkAsDeliveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_mark_as_delivered',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesUnmarkAsDeliveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_unmark_as_delivered',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesHideDeliveredEnabledEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_hide_delivered_toggle_enabled',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesHideDeliveredDisabledEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchases_complete_hide_delivered_toggle_disabled',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerCompletePurchasesStatusFilter = (value: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		customData: {
			label: value,
		},
		name: 'dlr_purchases_complete_status_filter_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerPurchaseSummaryExplanationCloseButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[AccountSlugs.COMPLETE],
		name: 'dlr_purchase_summary_explanation_close_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
