import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const postSaleOffers = (server: MirageServer): void => {
	server.get(`${API_ROUTES.PAYMENTS}/postSaleOffers/latest`, () => {
		return {
			data: {
				agreed: null,
				amount: 45364,
				amountFormatted: '45364.00',
				approvals: {
					seller: {
						createdAt: '2024-09-20T12:03:25.934Z',
						id: 45210,
						updatedAt: '2024-09-20T12:03:25.934Z',
						userId: 305521,
						userType: 'seller',
					},
				},
				closingBidId: 87536,
				createdAt: '2024-09-20T12:03:25.934Z',
				damageDescription: null,
				enquiryId: 179010,
				id: 35819,
				priceBreakdown: {
					broker_fee_amount: {
						amount: 622.8,
						amountFormatted: '622.80',
						amountNet: 519,
						amountNetFormatted: '519.00',
						amountVat: 103.8,
						amountVatFormatted: '103.80',
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193140,
						type: 'broker_fee_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					dealer_amount: {
						amount: 45986.8,
						amountFormatted: '45,986.80',
						amountNet: null,
						amountNetFormatted: null,
						amountVat: null,
						amountVatFormatted: null,
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193143,
						type: 'dealer_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					financing_amount: {
						amount: 0,
						amountFormatted: '0.00',
						amountNet: null,
						amountNetFormatted: null,
						amountVat: null,
						amountVatFormatted: null,
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193144,
						type: 'financing_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					seller_amount: {
						amount: 45364,
						amountFormatted: '45,364.00',
						amountNet: null,
						amountNetFormatted: null,
						amountVat: null,
						amountVatFormatted: null,
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193145,
						type: 'seller_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					total_fee_amount: {
						amount: 622.8,
						amountFormatted: '622.80',
						amountNet: 519,
						amountNetFormatted: '519.00',
						amountVat: 103.8,
						amountVatFormatted: '103.80',
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193142,
						type: 'total_fee_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					transport_fee_amount: {
						amount: 0,
						amountFormatted: '0.00',
						amountNet: 0,
						amountNetFormatted: '0.00',
						amountVat: 0,
						amountVatFormatted: '0.00',
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193141,
						type: 'transport_fee_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
					vehicle_amount: {
						amount: 45364,
						amountFormatted: '45,364.00',
						amountNet: null,
						amountNetFormatted: null,
						amountVat: null,
						amountVatFormatted: null,
						createdAt: '2024-09-20T12:03:25.934Z',
						detail: null,
						id: 193146,
						type: 'vehicle_amount',
						updatedAt: '2024-09-20T12:03:25.934Z',
						vatRate: 0.2,
					},
				},
				state: 'pending',
				type: 'closing_bid',
				updatedAt: '2024-09-20T12:03:25.934Z',
				userId: 1867,
				userType: 'dealer',
			},
		};
	});
};

export default postSaleOffers;
