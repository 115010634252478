/* eslint-disable import/prefer-default-export, no-unused-vars */

export const savedFilterEmpty = {
	createdAt: '2021-10-27T09:00:00.000Z',
	filterData: {
		ageFrom: null,
		ageTo: null,
		displayPriceFrom: null,
		displayPriceTo: null,
		excludeVehiclesOnFinance: false,
		fuel: [],
		includeOnlyInternalTestingVehicles: false,
		includeSold: false,
		includeUnderOffer: false,
		isAvailableForCollection: false,
		make: [],
		maxDistance: null,
		mileageFrom: null,
		mileageTo: null,
		previousKeepersCountFrom: null,
		previousKeepersCountTo: null,
		selectedVehicle: null,
		transmission: [],
		vehicleClass: [],
	},
	name: '',
	sendEmailAlert: false,
};

export const savedFilter = {
	createdAt: '2021-10-27T09:00:00.000Z',
	filterData: {
		ageFrom: 1,
		ageTo: 19,
		displayPriceFrom: 10500,
		displayPriceTo: 81500,
		excludeVehiclesOnFinance: false,
		fuel: ['Petrol', 'Hybrid', 'Diesel', 'Electric'],
		includeOnlyInternalTestingVehicles: false,
		includeSold: false,
		includeUnderOffer: true,
		isAvailableForCollection: true,
		make: ['volkswagen', 'audi'],
		maxDistance: 460,
		mileageFrom: 1000,

		mileageTo: 96000,
		// Temp remove for testing saved filters V2
		// model: ['a4', 'a3', 'Tiguan', 'Golf', 'Up'],
		previousKeepersCountFrom: 1,
		previousKeepersCountTo: 3,
		transmission: ['Semi-automatic', 'Manual', 'Automatic'],
		vehicleClass: ['Car', 'LCV'],
	},
	name: 'Test filter name',
	sendEmailAlert: true,
};
