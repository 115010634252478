import dayjs from 'dayjs';

import { createMirageVehicleSeed } from 'Mirage/utilities/helpers';
import type { AdditionalVehicleDetailsFromDynamoDb } from 'Services/dynamoDb/dynamoDb.types';
import { mapStateSlugToListingsTableSaleType } from 'Stores/AdditionalVehicleDetailsStore/AdditionalVehicleDetailsStore.helpers';
import type { StateSlug } from 'Types/enquiryStates';
import { ForSaleCategory } from 'Types/enquiryStates';

import type { SeedServer } from '../server.types';

import defaultScenario from './default';

const now = dayjs();

const addStateSlug = (stateSlug: StateSlug) => ({
	state: {
		slug: stateSlug,
	},
});

const buyItNowState = addStateSlug(ForSaleCategory.forSale);
const liveSaleState = addStateSlug(ForSaleCategory.saleActive);

const mockedVehicleData = [
	{
		expiryDate: now.add(10, 'seconds').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 10 seconds',
	},
	{
		expiryDate: now.add(1, 'minute').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 1 minute',
	},
	{
		expiryDate: now.add(30, 'minutes').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 30 minutes',
	},
	{
		expiryDate: now.add(1, 'hour').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 1 hour',
	},
	{
		expiryDate: now.add(1, 'hour').add(1, 'minute').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 1 hour and 1 minute',
	},
	{
		expiryDate: now.add(2, 'hours').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 2 hours',
	},
	{
		expiryDate: now.add(3, 'hours').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 3 hours',
	},
	{
		expiryDate: now.add(3, 'hours').add(1, 'minute').toISOString(),
		overrides: {
			...buyItNowState,
		},
		testName: 'Countdown - Expires in 3 hours and 1 minute',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 45000,
			...buyItNowState,
		},
		testName: 'Discount visualization - £5,000 discount',
	},
	{
		expiryDate: now.toISOString(),
		originalPrice: 50000,
		overrides: {
			displayPrice: 45000,
			...buyItNowState,
		},
		testName: 'Discount visualization - Processing state',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 50000,
			...buyItNowState,
		},
		testName: 'Discount visualization - Same original price',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 45000,
			isVatQualifying: true,
			...buyItNowState,
		},
		testName: 'Discount visualization - VAT Qualifying',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 45000,
			...liveSaleState,
		},
		testName: 'Discount visualization - £5,000 discount',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 50000,
			...liveSaleState,
		},
		testName: 'Discount visualization - Same original price',
	},
	{
		originalPrice: 50000,
		overrides: {
			displayPrice: 45000,
			isVatQualifying: true,
			...liveSaleState,
		},
		testName: 'Discount visualization - VAT Qualifying',
	},
];

export const ADDITIONAL_VEHICLE_DETAILS_MOCK = mockedVehicleData.reduce(
	(acc: AdditionalVehicleDetailsFromDynamoDb, { expiryDate, originalPrice, overrides }, i) => {
		const { slug } = overrides.state;
		const saleType = mapStateSlugToListingsTableSaleType(slug);

		if (!saleType) {
			return acc;
		}

		if (!acc[saleType]) {
			acc[saleType] = {};
		}

		acc[saleType][i + 1] = { expiryDate, originalPrice };
		return acc;
	},
	{},
);

const vehicles = mockedVehicleData.map(({ overrides, testName }) => ({
	overrides,
	testName,
}));

const additionalVehicleDetailsScenario = {
	...defaultScenario,
	seeds(server: SeedServer & { loadFixtures: () => void }): void {
		server.loadFixtures();

		vehicles.forEach(({ overrides, testName = '' }) => {
			createMirageVehicleSeed({ overrides: { isVatQualifying: false, ...overrides }, server, testName });
		});
	},
};

export default additionalVehicleDetailsScenario;
