import qs from 'qs';

import { LIST_TYPES } from 'Utilities/consts';
import dayjs from 'Utilities/dayjs';

import vehicleStates from '../../__tests__/states.mock';
import { ALL } from '../../components/VehicleList/filters/Filters.helpers';
import { ALL_MODELS_BY_MAKE } from '../../utilities/makeModel/makeModel.helpers';

const {
	cancelled_other_seller_issue: cancelled,
	for_sale: forSale,
	sale_active: saleActive,
	sold,
	under_offer: underOffer,
} = vehicleStates;

/**
 * Use request url to retrieve query params instead of request.queryParams
 * because pretender (MirageJs) doesn't play nicely with multiple params
 * of the same name.
 * https://github.com/miragejs/ember-cli-mirage/issues/420
 */
const getQueryParamsViaUrl = (request) => qs.parse(request?.url?.split('?')[1]);

export const resolve =
	(...resolvers) =>
	(...args) =>
		resolvers.every((f) => f(...args));

export const matchesByState = ({ state, vehicle }) => vehicle?.state?.slug === state?.slug;

export const matchesByStates = ({ states, vehicle }) => states.some((state) => matchesByState({ state, vehicle }));

export const propertyIncludesKeyword =
	(property) =>
	({ request }) => {
		const { keyword } = request.queryParams;

		if (!keyword) {
			return true;
		}

		return property?.toLowerCase().includes(keyword.toLowerCase());
	};

export const matchesSold = ({ request, vehicle }) => {
	const { includeSold } = request?.queryParams || {};

	if (includeSold) {
		return true;
	}

	return !matchesByState({ state: sold, vehicle });
};

export const matchServiceHistory = ({ request, vehicle }) => {
	const { serviceHistory } = getQueryParamsViaUrl(request);

	if (!serviceHistory || serviceHistory.includes(ALL) || serviceHistory.includes(vehicle?.serviceHistory)) {
		return true;
	}

	return false;
};

export const matchFuelType = ({ request, vehicle }) => {
	const fuelQuery = request?.queryParams?.fuel;

	return !fuelQuery || vehicle?.fuel === fuelQuery;
};

export const matchTransmission = ({ request, vehicle }) => {
	const { transmission } = getQueryParamsViaUrl(request);

	if (!transmission || transmission.includes(ALL) || transmission.includes(vehicle?.transmission)) {
		return true;
	}

	return false;
};

export const matchVehicleClass = ({ request, vehicle }) => {
	const { vehicleClass } = getQueryParamsViaUrl(request);

	if (!vehicleClass || vehicleClass.includes(ALL) || vehicleClass.includes(vehicle?.vehicleClass)) {
		return true;
	}

	return false;
};

export const matchUnderOffer = ({ request, vehicle }) => {
	const { includeUnderOffer } = request?.queryParams || {};

	if (includeUnderOffer) {
		return true;
	}

	return !matchesByState({ state: underOffer, vehicle });
};

export const matchCancelled = ({ vehicle }) => !matchesByState({ state: cancelled, vehicle });

export const matchesPriceRange = ({ request, vehicle }) => {
	const { sellerWillAcceptPrice } = vehicle;
	const { displayPriceFrom, displayPriceTo } = request?.queryParams || {};

	if (!displayPriceTo && !displayPriceFrom) {
		return true;
	}

	if (sellerWillAcceptPrice < displayPriceFrom) {
		return false;
	}

	if (sellerWillAcceptPrice > displayPriceTo) {
		return false;
	}

	return true;
};

export const matchPreviousOwners = ({ request, vehicle }) => {
	const { previousKeepersCount } = vehicle;
	const { previousKeepersCountFrom, previousKeepersCountTo } = request?.queryParams || {};

	if (!previousKeepersCountTo && !previousKeepersCountFrom) {
		return true;
	}

	if (previousKeepersCount < previousKeepersCountFrom) {
		return false;
	}

	if (previousKeepersCount > previousKeepersCountTo) {
		return false;
	}

	return true;
};

export const matchesMileageRange = ({ request, vehicle }) => {
	const { mileage } = vehicle;
	const { mileageFrom, mileageTo } = request?.queryParams || {};

	if (!mileageFrom && !mileageTo) {
		return true;
	}

	if (mileage < mileageFrom) {
		return false;
	}

	if (mileage > mileageTo) {
		return false;
	}

	return true;
};

export const matchAgeRange = ({ request, vehicle }) => {
	const { year } = vehicle;
	const { ageFrom, ageTo } = request?.queryParams || {};
	const thisYear = dayjs().year();
	const age = thisYear - year;

	if (!ageFrom && !ageTo) {
		return true;
	}

	if (age < ageFrom) {
		return false;
	}

	if (age > ageTo) {
		return false;
	}

	return true;
};

export const matchesListType = ({ request, schema, vehicle }) => {
	const { listType } = request?.queryParams || {};

	const listTypeMapToState = {
		[LIST_TYPES.auction]: saleActive,
		[LIST_TYPES.buyItNow]: forSale,
	};

	if (listType === LIST_TYPES.shortlist) {
		const isShortlisted = Boolean(schema?.shortlistedEnquiries?.findBy({ enquiryId: vehicle?.id }));
		const matchesForSale = matchesByState({ state: forSale, vehicle });
		const matchesSaleActive = matchesByState({ state: saleActive, vehicle });
		const matchesState = matchesForSale || matchesSaleActive;

		return isShortlisted && matchesState;
	}

	return matchesByState({ state: listTypeMapToState[listType], vehicle });
};

export const matchVehicleGrade = ({ request, vehicle }) => {
	const { exteriorGrade } = getQueryParamsViaUrl(request);

	if (!exteriorGrade || exteriorGrade.includes(ALL) || exteriorGrade.includes(vehicle?.exteriorGrade)) {
		return true;
	}

	return false;
};

export const matchesMake = ({ request, vehicle }) => {
	const { make } = getQueryParamsViaUrl(request);
	if (!make) {
		return true;
	}
	const requestedMakes = Object.keys(make);

	return requestedMakes.includes(vehicle?.make);
};

export const matchesModel = ({ request, vehicle }) => {
	const { make } = getQueryParamsViaUrl(request);
	if (!make || make[vehicle?.make] === ALL_MODELS_BY_MAKE) {
		return true;
	}

	const makes = Object.keys(make);
	const models = Object.values(make);

	if (!makes.includes(vehicle.make)) {
		return false;
	}

	return !models || models.includes(vehicle?.model);
};
