import { getSnowplowEventPayload, logAnalyticsEvent } from '../../analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from '../../analytics.const';
import type { GalleryEvent } from '../galleryEventsRedesign/types';

const { PURCHASE_SUMMARY_GALLERY } = ANALYTICS_CATEGORIES;

export const triggerKeyDocumentsGalleryZoomResetClickedEvent = ({ kind }: GalleryEvent): void => {
	const eventPayload = getSnowplowEventPayload({
		category: PURCHASE_SUMMARY_GALLERY,
		customData: { label: kind },
		name: 'dlr_key_documents_gallery_zoom_original_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
