/* eslint-disable local-rules/function-naming */
import type { AxiosInterceptorManager, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { cls } from '@motorway/motorway-api-provider';

import { ACCESS_TOKEN_CLS_KEY } from 'Auth/consts';
import { logger } from 'Services/logger/logger';
import { SECOND } from 'Utilities/consts';
import { redactSensitiveFieldsFromAxiosError } from 'Utilities/error/handleAxiosError';
import { sleep } from 'Utilities/utils';

import type { ApiResponseForJsend } from './apiClient.types';

type AxiosResponseInterceptor = Required<Parameters<AxiosInterceptorManager<AxiosResponse>['use']>>;

const AUTHORIZATION_HEADER_NAME = 'Authorization' as const;

export const AXIOS_RESPONSE: unique symbol = Symbol('AxiosResponse');

export const isAxiosResponse = (res: unknown): res is AxiosResponse => {
	if (typeof res !== 'object' || res === null) {
		return false;
	}

	const primaryMatch = AXIOS_RESPONSE in res && res[AXIOS_RESPONSE] === true;

	if (primaryMatch) {
		return true;
	}

	// If the response is an AxiosResponse but it is not tagged with the AXIOS_RESPONSE symbol,
	// we still have to support it for, however we should warn the developer that they should tag the response.
	const secondaryMatch = 'status' in res && typeof res.status === 'number' && 'headers' in res && 'config' in res;

	if (secondaryMatch) {
		// eslint-disable-next-line no-console
		console.warn(
			'isAxiosResponse: This is an AxiosResponse but it is not tagged with the AXIOS_RESPONSE symbol. This is likely new usage of Axios. Consider using `apiClientAxiosOnFulfilled` to tag the response.',
		);

		return true;
	}

	return false;
};

export const apiClientAxiosOnFulfilled: AxiosResponseInterceptor[0] = (response) => {
	// We tag the response with a custom symbol so we can identify it later
	response[AXIOS_RESPONSE] = true;

	return response;
};

export const apiClientAxiosOnRejected: AxiosResponseInterceptor[1] = (error) => {
	return Promise.reject(redactSensitiveFieldsFromAxiosError(error));
};

const getAccessTokenFromCls = (config: AxiosRequestConfig) => {
	const accessToken = cls.storage.get(ACCESS_TOKEN_CLS_KEY);

	if (!accessToken) {
		logger.error({
			context: {
				extra: { axiosConfig: config },
			},
			message: 'No access token found in cls storage',
			scope: 'createAxiosInstanceForService',
		});
	}

	return accessToken;
};

export const apiClientAxiosOnRequestSetAuthTokenHeader = (config: InternalAxiosRequestConfig) => {
	const accessToken = getAccessTokenFromCls(config);

	if (accessToken) {
		config.headers[AUTHORIZATION_HEADER_NAME] = accessToken;
	}

	return config;
};

export const apiClientAxiosOnRequestSetAuthTokenWithBearerHeader = (config: InternalAxiosRequestConfig) => {
	const accessToken = getAccessTokenFromCls(config);

	if (accessToken) {
		config.headers[AUTHORIZATION_HEADER_NAME] = `Bearer ${accessToken}`;
	}

	return config;
};

export const mockAxiosResponse = <P extends ApiResponseForJsend>(response: P) =>
	({
		// eslint-disable-next-line local-rules/boolean-naming
		[AXIOS_RESPONSE]: true,

		data: response,
		status: 200,
		statusText: 'OK',
	}) as AxiosResponse<P>;

// eslint-disable-next-line local-rules/function-naming
export const withRetry = async <T>(
	fn: () => Promise<T>,
	opts: {
		/**
		 * Delay in seconds
		 */
		delay: number;
		/**
		 * Delay increment in seconds
		 */
		delayIncrement?: number;
		/**
		 * Callback to run on error
		 */
		onError?: (error: unknown) => void;
		/**
		 * Number of retries
		 */
		retries: number;
	},
): Promise<T> => {
	try {
		return await fn();
	} catch (error) {
		if (opts.onError) opts.onError(error);

		if (opts.retries === 0) throw error;

		await sleep(opts.delay);

		return withRetry(fn, {
			...opts,
			delay: opts.delay + (opts.delayIncrement ?? 2 * SECOND),
			retries: opts.retries - 1,
		});
	}
};
