require('dotenv-defaults').config();

const isProd = process.env.APPLICATION_ENVIRONMENT === 'production';
const isStaging = process.env.APPLICATION_ENVIRONMENT === 'staging';
const isDev = process.env.NODE_ENV === 'development';

/**
 * A sale url is one of the following:
 * - /example
 * - /example?query=string
 * - /example#hash
 * - /example/path/file.json
 * - /example/path/file.json?query=string
 * - View regex at https://regex-vis.com/
 */
function isSafeRelativeUrl(url) {
	/**
	 * TODO: Either find a package that does this or improve to prevent users from being able to navigate to executables
	 * e.g /example.exe to note this is a potential security risk, possible but unlikely
	 */

	const relativePathRegex = /^\/[\w\-./]*(?:\?[\w=&-]*)?(?:#[\w\\-]*)?$/;

	return relativePathRegex.test(url);
}
module.exports = {
	isDev,
	isProd,
	isSafeRelativeUrl,
	isStaging,
};
