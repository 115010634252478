import { Factory } from 'miragejs';

import type { ShortlistedEnquiry } from 'Mirage/types';
import dayjs from 'Utilities/dayjs';

const shortlistFactory = Factory.extend<ShortlistedEnquiry>({
	enquiryId(enquiryId: number) {
		return enquiryId + 1;
	},
	updatedAt(updatedAt: string | number) {
		return typeof updatedAt === 'string' ? updatedAt : dayjs().toISOString();
	},
});

export default shortlistFactory;
