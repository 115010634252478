export interface MotData {
	MOTAdvisoriesUnavailable: boolean;
	advisories: string[];
	status: MotStatus;
}

export enum MotStatus {
	Exempt = 'EXEMPT',
	Fail = 'FAILED',
	Pass = 'PASSED',
	Unavailable = 'NOT_AVAILABLE',
}
