import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

interface TriggerSectionScrollEventProps {
	category: string;
	from: string;
	to: string;
}

export const triggerSectionScrollEvent = ({ category, from, to }: TriggerSectionScrollEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category,
		customData: {
			label: `from: ${from}, to: ${to}`,
		},
		name: `dlr_page_scroll`,
		schemaName: SNOWPLOW_EVENTS.SCROLL.SCHEMA,
		version: SNOWPLOW_EVENTS.SCROLL.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
