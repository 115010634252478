import { API_ROUTES } from 'Utilities/routes';

const DUMMY_MAGIC_LINK =
	'https://motorway.sandbox.invoiced.com/login/5d41402abc4b2a76b9719d911017c592.5d41402abc4b2a76b9719d911017c592';

const billingRoutes = (server) => {
	server.get(`${API_ROUTES.INVOICED_MAGIC_LINK}`, () => ({
		magicLoginLink: DUMMY_MAGIC_LINK,
	}));
};

export default billingRoutes;
