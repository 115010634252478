import type { Request, Server } from 'miragejs';

import type { AppSchema } from 'Mirage/types';
import type { WithdrawalStatus } from 'Services/motorway/services/withdrawals/withdrawals.types';
import { API_ROUTES } from 'Utilities/routes';

export const withdrawalRoutes = (server: Server): void => {
	server.get(`${API_ROUTES.WITHDRAWALS}/:withdrawalId`, (schema: AppSchema, request: Request) => {
		const withdrawalId = request?.params?.withdrawalId;

		const data: Omit<WithdrawalStatus, 'state'> = {
			amount: 100,
			currency: 'GBP',
			dealerContactId: 123,
			dealerId: 123,
			destinationId: '12345678',
			id: withdrawalId,
			provider: 'MODULR',
			reference: '12345678',
			sourceId: 123,
		};

		if (withdrawalId === 'pending') {
			return {
				data: {
					...data,
					state: 'pending',
				} as WithdrawalStatus,
			};
		}

		if (withdrawalId === 'done') {
			return {
				data: {
					...data,
					state: 'done',
				} as WithdrawalStatus,
			};
		}

		if (withdrawalId === 'failed') {
			return {
				data: {
					...data,
					state: 'failed',
				} as WithdrawalStatus,
			};
		}

		return {
			data: {
				...data,
				state: 'initial',
			} as WithdrawalStatus,
		};
	});
};
