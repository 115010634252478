import recentSoldFixtureData from 'Mirage/fixtures/insights/recentSold';
import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const recentSoldRoutes = (server: MirageServer): void => {
	server.get(API_ROUTES.INSIGHTS_VEHICLES_RECENT_SOLD, () => {
		return recentSoldFixtureData;
	});
};

export default recentSoldRoutes;
