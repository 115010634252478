import { useRef } from 'react';
import T from 'i18n-react';
import { useRouter } from 'next/router';

import { toast } from '@motorway/mw-highway-code/pre-alpha';

import texts from 'Components/texts.json';
import { logger } from 'Services/logger/logger';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import ROUTES from 'Utilities/routes';

T.setTexts(texts);

interface UseSocketToastHandler {
	onReconnectAttempt: (attemptNumber: number) => void;
	onReconnected: (attemptNumber: number) => void;
}

export const RECONNECTION_ATTEMPT_LIMIT_BEFORE_RAISE_ERROR = 3;
const PAGES_ALLOWED_TO_DISPLAY_SOCKET_ERROR_TOAST = [ROUTES.VEHICLE.href] as string[];

export const useSocketToastHandler = (): UseSocketToastHandler => {
	const { pathname } = useRouter();
	const isSocketErrorToastEnabled = useFeatureToggle(FEATURES.socketErrorToast);
	const dismissCallbackRef = useRef<Nullable<Noop>>(null);

	const logSocketWarning = (message: string) => {
		logger.warn({
			message,
			scope: 'useSocketToastHandler',
		});
	};

	const onReconnected = (attemptNumber: number) => {
		if (!isSocketErrorToastEnabled || dismissCallbackRef.current === null) {
			return;
		}
		dismissCallbackRef.current();
		dismissCallbackRef.current = null;
		logSocketWarning(`WebSocket reconnected after ${attemptNumber} attempts`);
	};

	const onReconnectAttempt = (attemptNumber: number) => {
		const isToastVisible = dismissCallbackRef.current;
		const canDisplayToastOnCurrentPage = PAGES_ALLOWED_TO_DISPLAY_SOCKET_ERROR_TOAST.includes(pathname);
		const isAboveOrEqualToReconnectionLimit = attemptNumber >= RECONNECTION_ATTEMPT_LIMIT_BEFORE_RAISE_ERROR;

		if (
			isSocketErrorToastEnabled &&
			canDisplayToastOnCurrentPage &&
			isAboveOrEqualToReconnectionLimit &&
			!isToastVisible
		) {
			dismissCallbackRef.current = toast({
				content: `${T.translate('toast.socketDisconnected')}`,
				onClose: () => {
					dismissCallbackRef.current = null;
				},
				persistent: true,
				variant: 'error',
			});
			logSocketWarning(`Websocket failed to reconnect after ${attemptNumber} attempts, display toast`);
		}
	};

	return { onReconnectAttempt, onReconnected };
};
