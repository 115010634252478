import { LISTINGS_TABLE_SALE_TYPES } from 'Services/dynamoDb/dynamoDb.const';
import { ForSaleCategory } from 'Types/enquiryStates';
import { LIST_TYPES } from 'Utilities/consts';

export const LIST_TYPE_TO_LISTINGS_TABLE_SALE_TYPE = {
	[LIST_TYPES.buyItNow]: LISTINGS_TABLE_SALE_TYPES.buyItNow,
	[LIST_TYPES.auction]: LISTINGS_TABLE_SALE_TYPES.liveSale,
	[LIST_TYPES.shortlist]: undefined,
};

export const STATE_SLUG_TO_LISTINGS_TABLE_SALE_TYPE = {
	[ForSaleCategory.forSale]: LISTINGS_TABLE_SALE_TYPES.buyItNow,
	[ForSaleCategory.saleActive]: LISTINGS_TABLE_SALE_TYPES.liveSale,
};
