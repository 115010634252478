import type { MotData } from 'Types/motData';
import { MotStatus } from 'Types/motData';

const motData: MotData[] = [
	{
		advisories: [],
		MOTAdvisoriesUnavailable: false,
		status: MotStatus.Pass,
	},
	{
		advisories: [
			'Offside Front Type worn close to legal limit/worn to edge (5.2.3 (e))',
			'Nearside Front Brake disk worn, pitted or scored, but not seriously weakened (1.1.14 (a) (ii))',
			'Windscreen damaged but not adversely affecting driver&aposs view (3.2 (a) (i))',
			'Offside Front Inner Steering linkage ball joint has slight play (2.1.3 (b) (i))',
		],
		MOTAdvisoriesUnavailable: false,
		status: MotStatus.Pass,
	},
	{
		advisories: ['Offside Front Type worn close to legal limit/worn to edge (5.2.3 (e))'],
		MOTAdvisoriesUnavailable: false,
		status: MotStatus.Pass,
	},
	{
		advisories: [
			'Offside Front Type worn close to legal limit/worn to edge (5.2.3 (e))',
			'Nearside Front Brake disk worn, pitted or scored, but not seriously weakened (1.1.14 (a) (ii))',
			'Windscreen damaged but not adversely affecting driver&aposs view (3.2 (a) (i))',
			'Offside Front Inner Steering linkage ball joint has slight play (2.1.3 (b) (i))',
		],
		MOTAdvisoriesUnavailable: false,
		status: MotStatus.Fail,
	},
	{
		advisories: [],
		MOTAdvisoriesUnavailable: true,
		status: MotStatus.Exempt,
	},
	{
		advisories: [],
		MOTAdvisoriesUnavailable: true,
		status: MotStatus.Pass,
	},
	{
		advisories: [],
		MOTAdvisoriesUnavailable: true,
		status: MotStatus.Unavailable,
	},
	{
		advisories: [],
		MOTAdvisoriesUnavailable: true,
		status: MotStatus.Fail,
	},
];

export default motData;
