import { useContext } from 'react';

import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';

const useFeatureToggle = (feature) => {
	const featuresContext = useContext(FeaturesContext);

	if (!featuresContext) {
		throw new Error('useFeatureToggle must be used within a FeaturesProvider');
	}

	if (!feature) {
		return false;
	}
	return featuresContext[feature];
};

export default useFeatureToggle;

export { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
