import camelCase from 'lodash/camelCase';
import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import getConfig from 'next/config';

import { isSSR } from 'Utilities/helpers';

import statesMock from '../../__tests__/states.mock';

const cypressStub = {
	publicRuntimeConfig: {
		DEALER_WEBAPP_URL: process.env.DEALER_WEBAPP_URL,
		STATIC_ASSETS_DOMAIN: process.env.STATIC_ASSETS_DOMAIN,
	},
};

export const getMirageConfig = () => {
	if (!isSSR() && window.Cypress) {
		return cypressStub;
	}

	return getConfig();
};

export const createPlaceholderText = (str) => `${str.replace(' ', '+')}&`;

export const setMirageVehicleTestName = (testName = 'Test vehicle') => {
	const make = ''; // Make is heavily formatted, so we use model instead
	const model = `MIRAGE - ${testName}`;
	const spec = { make, model };

	return { make, model, spec };
};

export const createMirageVehicleSeed = ({ count = 1, overrides = {}, server, status = 'inForSale', testName = '' }) => {
	Array(count)
		.fill()
		.forEach((_, index) => {
			const name = testName ? setMirageVehicleTestName(`${testName} #${index + 1}`) : {};
			server.create('vehicle', status, { ...name, ...overrides });
		});
};

export const seedAllVehicleStates = ({ count, overrides, server, testName }) => {
	Object.keys(statesMock).forEach((state) => {
		const status = `in${upperFirst(camelCase(state))}`;
		const statusName = startCase(status);
		const name = testName ? `${testName} - ${statusName}` : statusName;
		createMirageVehicleSeed({ count, overrides, server, status, testName: name });
	});
};
