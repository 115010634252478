import { Model } from 'miragejs';

import type { Vehicle } from 'Types/vehicle';

const models = {
	genericModel: Model,
	make: Model,
	savedFilter: Model,
	shortlistedEnquiry: Model,
	transactions: Model,
	vehicle: Model.extend<Partial<Vehicle>>({}),
};

export default models;
