import { ForSaleCategory } from 'Types/enquiryStates';
import type { SaleTimes } from 'Types/saleTimes';
import { LIST_TYPES } from 'Utilities/consts';

export const MIRAGE_DEFAULT_EMPTY_VEHICLE_LIST_RESPONSE = {
	hasMore: false,
	totalCount: 0,
	vehicles: [],
};

const mockVehicleListCsv = `
	Make, Model, Year, Color
	Toyota, Camry, 2020, Silver
	Honda, Civic, 2022, Blue
	Ford, F-150, 2021, Black
	Tesla, Model 3, 2023, Red
`;

export const MIRAGE_VEHICLE_LIST_CSV_RESPONSE = {
	error: { data: 'URL expired or never created', statusCode: 404 },
	processing: { data: 'Not ready Yet', statusCode: 202 },
	success: { data: mockVehicleListCsv, statusCode: 200 },
};

type ListTypes = keyof typeof LIST_TYPES;

interface ShouldShowVehiclesForSaleTimeProps {
	listType: ListTypes;
	saleStartTolerance?: number;
	saleTimes: SaleTimes;
	shouldUseListType?: boolean;
}

interface GetValidVehicleStatesForSaleTimeProps {
	saleStartTolerance?: number;
	saleTimes: SaleTimes;
}

interface GetValidVehicleStatesForListTypeProps {
	listType: ListTypes;
	saleStartTolerance?: number;
	saleTimes: SaleTimes;
}

// The BE allows vehicles for the sale to be fetched slightly before it starts
// This is to ensure there isn't a race condition with the front-end fetching slightly too early
const defaultSaleStartTolerance = 1000 * 60 * 5; // 5 minutes

const validVehicleStatesForListType = {
	[LIST_TYPES.auction]: [ForSaleCategory.saleActive],
	[LIST_TYPES.buyItNow]: [ForSaleCategory.forSale],
	[LIST_TYPES.shortlist]: [ForSaleCategory.saleActive, ForSaleCategory.forSale],
};

export const getValidVehicleStatesForSaleTime = ({
	saleStartTolerance = defaultSaleStartTolerance,
	saleTimes,
}: GetValidVehicleStatesForSaleTimeProps): ForSaleCategory[] => {
	const { isSaleActive, millisecondsRemaining } = saleTimes || {};

	const shouldIncludeLiveSale = isSaleActive || millisecondsRemaining <= saleStartTolerance;

	return [
		...validVehicleStatesForListType.buyItNow,
		...(shouldIncludeLiveSale ? validVehicleStatesForListType.auction : []),
	];
};

export const getValidVehicleStatesForListType = ({
	listType,
	saleStartTolerance,
	saleTimes,
}: GetValidVehicleStatesForListTypeProps): ForSaleCategory[] => {
	const validVehicleStates = getValidVehicleStatesForSaleTime({ saleStartTolerance, saleTimes });
	return validVehicleStates.filter((state) => validVehicleStatesForListType[listType].includes(state));
};

export const shouldShowVehiclesForSaleTime = ({ listType, saleTimes }: ShouldShowVehiclesForSaleTimeProps): boolean => {
	const validStates = getValidVehicleStatesForListType({ listType, saleTimes });
	return validStates.length > 0;
};
