/* eslint-disable import/no-extraneous-dependencies */
import isChromatic from 'chromatic/isChromatic';
import { datatype, date, random, vehicle } from 'faker';
import { Factory } from 'miragejs';

import { VehicleGrades } from '@motorway/motorway-storybook-cra';

import { SERVICE_HISTORY } from 'Components/VehicleList/VehicleListFilters.consts';
import genericModels from 'Mirage/fixtures/genericModels';
import makes from 'Mirage/fixtures/makes';
import motData from 'Mirage/fixtures/motData';
import { vehicleStatusTraits } from 'Mirage/traits';
import vehicleStates from 'Tests/states.mock';
import { VEHICLE_BATTERY_OWNERSHIP, VEHICLE_CHARGING_CABLES } from 'Utilities/consts';
import dayjs from 'Utilities/dayjs';
import { toTitleCase } from 'Utilities/formatters';

import vehicleDetailsMock from '../../fixtures/vehicle_detail.json';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { id, ...defaultVehicleDetails } = vehicleDetailsMock; // remove the ID to allow mirage to control the vehicle ID

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const vehicleFactory = Factory.extend<Record<string, any>>({
	...defaultVehicleDetails,
	displayPrice() {
		return isChromatic() ? 20150 : datatype.number({ max: 10000, min: 5000 });
	},
	distance() {
		return isChromatic() ? 100 : datatype.number({ max: 1000, min: 5 });
	},
	electricsProblemsDesc() {
		return isChromatic()
			? 'Other'
			: random.arrayElement([
					null,
					'USB port is not connected.',
					'spanner on the dash board is a bit faulty but the vehicle still functions perfectly',
					'Other',
			  ]);
	},
	enquiryId() {
		return vehicle.id; // Set to the mirage assigned ID so we can match this value to the vehicle in routing
	},
	exteriorGrade() {
		return isChromatic() ? VehicleGrades.GOLD : random.arrayElement(Object.values(VehicleGrades));
	},
	fuel() {
		return vehicle.fuel();
	},
	hasElectricsProblems() {
		return isChromatic() ? false : random.arrayElement([null, true, false]);
	},
	initialValuation() {
		return Number(this.sellerWillAcceptPrice) + Number(this.prepCosts);
	},
	interiorType() {
		return isChromatic() ? 'Suede' : random.arrayElement(['Hidden', 'Leather', 'Cloth', 'Half leather', 'Suede', null]);
	},
	isVatQualifying: isChromatic() ? true : random.arrayElement([true, false]),
	keepersList() {
		return Array(this.previousKeepersCount)
			.fill(null)
			.map((_, index) => ({ startDate: `201${index}-0${index}-2${index}` }));
	},
	leadPhoto: {
		meta: {
			height: 896,
			rotate: 0,
			scaleX: 1,
			scaleY: 1,
			width: 1280,
			x: 0,
			y: 0,
		},
		url: 'https://motorway-photos-modified-stage.imgix.net/1400/1400/1400-exterior_front_driver-modified-1738687624933.jpeg',
	},
	make() {
		const { makeId } = this.makeModel || {};
		return makeId ? toTitleCase(makes?.find(({ id: vehicleMakeId }) => vehicleMakeId === makeId)?.slug) : 'Other';
	},
	makeModel() {
		return isChromatic() ? genericModels[0] : random.arrayElement(genericModels);
	},
	mileage() {
		return isChromatic() ? 50000 : datatype.number({ max: 100000, min: 5000 });
	},
	model() {
		return this.makeModel.name;
	},
	motData() {
		return isChromatic() ? motData[0] : random.arrayElement(motData);
	},
	prepCosts: datatype.number({ max: 1500, min: 100 }),
	prettyVrm() {
		return isChromatic() ? 'Outatime' : this.vrm;
	},
	previousKeepersCount() {
		return datatype.number({ max: 6, min: 1 });
	},
	priceHigh() {
		return datatype.number({ max: 10000, min: 5000 });
	},
	sellerWillAcceptPrice() {
		return isChromatic() ? 19850 : datatype.number({ max: 100000, min: 5000 });
	},
	serviceHistory() {
		return isChromatic() ? SERVICE_HISTORY.FULL : random.arrayElement(Object.values(SERVICE_HISTORY));
	},
	spec() {
		const body = isChromatic() ? 'Hatchback' : random.arrayElement(['Hatchback', 'Coupe', 'Estate', 'Convertible']);
		const isElectric = isChromatic() ? false : this.fuel === 'Electric';

		return {
			batteryOwnership: isElectric ? random.arrayElement(VEHICLE_BATTERY_OWNERSHIP) : null,
			body,
			bodyCategory: body,
			chargingCable: isElectric ? random.arrayElement({ ...VEHICLE_CHARGING_CABLES, none: null }) : null,
			colour: vehicle.color(),
			engineSize: datatype.number({ max: 8500, min: 1000 }),
			firstRegistered: date.past(1),
			fuel: this.fuel,
			interiorType: this.interiorType,
			make: this.make,
			model: this.model,
			transmission: this.transmission,
			vin: this.vin,
		};
	},
	state() {
		return isChromatic() ? vehicleStates.for_sale : random.arrayElement(vehicleStates);
	},
	transmission() {
		return isChromatic() ? 'Manual' : random.arrayElement(['Semi-automatic', 'Manual', 'Automatic']);
	},
	userBid() {
		return {
			createdAt: isChromatic() ? '1985/10/26' : date.past(0.2),
			id: 999999,
			maximumBid: this.displayPrice,
			type: 'proxy',
			value: this.displayPrice,
		};
	},
	vehicleClass() {
		return isChromatic() ? 'Car' : random.arrayElement(['Car', 'LCV']);
	},
	vin() {
		return vehicle.vin();
	},
	vrm() {
		return isChromatic() ? 'Outatime' : vehicle.vrm();
	},
	winningBid() {
		return isChromatic() ? 19550 : datatype.number({ max: 10000, min: 5000 });
	},
	year() {
		return isChromatic() ? 1955 : dayjs(date.past(21)).year();
	},
	...vehicleStatusTraits,
});

export default vehicleFactory;
