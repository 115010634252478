import type { Server } from 'miragejs';

import { API_ROUTES } from 'Utilities/routes';

const saleRulesRoutes = (server: Server): void => {
	server.get(`${API_ROUTES.FEES}`, () => ({
		FEES: [],
		MAXIMUM_PURCHASE_VALUE_LENGTH: 9,
		PROXY_BID_INCREMENT: 1,
	}));

	server.get(`${API_ROUTES.FEES_CALCULATE}`, () => ({
		value: 0,
	}));
};

export default saleRulesRoutes;
