import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerSectionLinksClickedEventProps {
	category: string;
	label: string;
	source: string;
}

export const triggerSectionLinksClickedEvent = ({
	category,
	label,
	source,
}: TriggerSectionLinksClickedEventProps): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category,
				customData: { label },
				name: `dlr_${source}_link_clicked`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
