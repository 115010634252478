import pipe from 'lodash/flow';
import isArray from 'lodash/isArray';

import { API_ROUTES } from 'Utilities/routes';

import vehicleStates from '../../__tests__/states.mock';
import { matchesByStates, propertyIncludesKeyword, resolve } from '../utilities/filterResolvers';

const {
	cancelled_other_seller_issue: cancelled,
	sale_active: saleActive,
	sold,
	sold_documents_reviewed: soldDocumentsReviewed,
	under_offer: underOffer,
} = vehicleStates;

const matchKeyword = (args) => {
	const { vehicle } = args;

	const includesVrm = propertyIncludesKeyword(vehicle.prettyVrm)(args);
	const includesModel = propertyIncludesKeyword(vehicle.model)(args);
	const includesMake = propertyIncludesKeyword(vehicle.make)(args);

	if (includesVrm || includesModel || includesMake) {
		return true;
	}

	return false;
};

export const getPaginatedData = ({ data, request }) => {
	const { limit, offset } = request.queryParams;
	const paginatedData = data.models.splice(offset, offset + limit);
	return paginatedData;
};

export const matchVehicles = ({ request, schema, states }) => {
	const queryStates = isArray(states) ? states : [states];

	const vehicles = schema.vehicles.where((vehicle) =>
		resolve(matchesByStates, matchKeyword)({ request, states: queryStates, vehicle }),
	);

	return {
		data: vehicles,
		request,
		schema,
		states,
	};
};

export const getAccountSectionResponse = ({ data, request }) => {
	return {
		count: data.models.length,
		results: getPaginatedData({ data, request }),
	};
};

const accountHandler = (schema, request, states) =>
	pipe(matchVehicles, getAccountSectionResponse)({ request, schema, states });

const account = (server) => {
	server.get(`${API_ROUTES.BIDDING_HISTORY_BIDS_ACTIVE}`, (schema, request) =>
		accountHandler(schema, request, saleActive),
	);

	server.get(`${API_ROUTES.BIDDING_HISTORY_BIDS_UNDER_OFFER}`, (schema, request) =>
		accountHandler(schema, request, underOffer),
	);

	server.get(`${API_ROUTES.BIDDING_HISTORY_BIDS_DIDNT_WIN}`, (schema, request) =>
		accountHandler(schema, request, sold),
	);

	server.get(`${API_ROUTES.BIDDING_HISTORY_PURCHASES_COMPLETE}`, (schema, request) =>
		accountHandler(schema, request, [sold, soldDocumentsReviewed]),
	);

	server.get(`${API_ROUTES.BIDDING_HISTORY_PURCHASES_CANCELLED}`, (schema, request) =>
		accountHandler(schema, request, cancelled),
	);

	server.get(`${API_ROUTES.BIDDING_HISTORY_COUNTS}`, () => {
		return {
			active: 10,
			cancelled: 5,
			complete: 2231,
			'didnt-win': 123,
			'under-offer': 15,
		};
	});

	/**
	 * No point replicating BE functionality here. Just return some dummy data.
	 * Different values to test the UI.
	 */
	server.get(`${API_ROUTES.BIDDING_HISTORY_STATUS_COUNTS}`, () => {
		return {
			allStatus: 1100,
			awaitingConfirmation: 500,
			checksComplete: 14,
			deliveryRequested: 486,
			reviewingDocs: 0,
		};
	});
};

export default account;
