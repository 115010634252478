import type { AnyFactories, AnyModels } from 'miragejs/-types';
import type { ServerConfig } from 'miragejs/server';

import { ENVIRONMENTS } from '../../utilities/consts';
import factories from '../factories';
import fixtures from '../fixtures';
import IntegerIDManager from '../IntegerIDManager';
import models from '../models';
import {
	account as accountRoutes,
	bids as bidsRoutes,
	billing as billingRoutes,
	delivery as deliveryRoutes,
	financeStatus as financeStatusRoutes,
	genericModels as genericModelsRoutes,
	insightsRecentSold as insightsRecentSoldRoutes,
	makeModelCount as makeModelRoutes,
	otp as otpRoutes,
	paymentOptions as paymentOptionsRoutes,
	paymentPostSaleOffers as paymentPostSaleOffersRoutes,
	payments as paymentsRoutes,
	process as paymentProcessRoutes,
	sale as saleRoutes,
	saleRules as saleRulesRoutes,
	savedFilters as savedFiltersRoutes,
	shortlistRoutes,
	transactionRoutes,
	vehicle as vehicleRoutes,
	withdrawalRoutes,
} from '../routes';
import { getMirageConfig as getConfig } from '../utilities/helpers';

const { publicRuntimeConfig } = getConfig();

const { DEALER_WEBAPP_URL, SNOWPLOW_COLLECTOR_URL, SNOWPLOW_MINI_URL } = publicRuntimeConfig;

const defaultScenario: ServerConfig<AnyModels, AnyFactories> = {
	environment: ENVIRONMENTS.DEVELOPMENT,
	factories,
	fixtures,
	identityManagers: {
		/**
		 * Mirage types for IdentityManager doesn't allow for custom identity managers.
		 * As we use numbers for ids, we can ignore the type error.
		 */
		// @ts-expect-error
		application: IntegerIDManager,
	},
	models,
	routes() {
		this.urlPrefix = DEALER_WEBAPP_URL;
		this.namespace = '';

		accountRoutes(this);
		bidsRoutes(this);
		billingRoutes(this);
		deliveryRoutes(this);
		genericModelsRoutes(this);
		insightsRecentSoldRoutes(this);
		makeModelRoutes(this);
		otpRoutes(this);
		paymentProcessRoutes(this);
		paymentOptionsRoutes(this);
		paymentPostSaleOffersRoutes(this);
		paymentsRoutes(this);
		saleRoutes(this);
		saleRulesRoutes(this);
		savedFiltersRoutes(this);
		shortlistRoutes(this);
		transactionRoutes(this);
		vehicleRoutes(this);
		withdrawalRoutes(this);
		financeStatusRoutes(this);

		this.passthrough();

		this.passthrough('https://static.stage-pro.motorway.co.uk/**');
		this.passthrough(`${SNOWPLOW_COLLECTOR_URL}/**`);
		this.passthrough(`${SNOWPLOW_MINI_URL}/**`);
		this.passthrough(`https://insights.public.stage.motorway.cloud/**`);
		this.passthrough(`https://cdn.optimizely.com/**`);
	},
	seeds(server) {
		server.loadFixtures();
		/**
		 * Mirage doesn't have typings for traits yet.
		 * Casting as any to avoid typescript errors.
		 */
		server.createList('vehicle', 10, 'inSoldDocumentsReviewed' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('vehicle', 50, 'inSaleActive' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('vehicle', 20, 'inForSale' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('vehicle', 30, 'inUnderOffer' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('vehicle', 50, 'inSold' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('vehicle', 10, 'inCancelledOtherSellerIssue' as any); // eslint-disable-line @typescript-eslint/no-explicit-any
		server.createList('transaction', 25);
		server.createList('shortlistedEnquiry', 160);
	},
};

export default defaultScenario;
