// eslint-disable-next-line import/no-extraneous-dependencies
import { datatype, date } from 'faker';
import { Factory } from 'miragejs';

import type { PriceBreakdown, PriceBreakdownType } from 'Components/pages/VehiclePayment/VehiclePayment.types';
import type { SingleTransactionExtended } from 'Components/pages/Wallet/Wallet.const';
import { transactionScenarios } from 'Mirage/utilities/transaction.helpers';
import dayjs from 'Utilities/dayjs';

const createBreakdown = (amount: number, type: PriceBreakdownType, vatRate = 0.2): PriceBreakdown => ({
	amount,
	amountNet: amount - amount * vatRate,
	amountVat: amount * vatRate,
	createdAt: dayjs(date.past(5)).toISOString(),
	id: 1,
	type,
	updatedAt: dayjs(date.past(5)).toISOString(),
	vatRate,
});

const transactionFactory = Factory.extend<SingleTransactionExtended>({
	amendmentAmount(i: number) {
		return transactionScenarios[i]?.hasPriceReduction ? datatype.number({ max: -50, min: -750 }) : 0;
	},
	initialOfferAmount() {
		return datatype.number({ max: 15000, min: 3000 });
	},
	priceBreakdown(i: number) {
		const vehicleAmount = Number(this.initialOfferAmount) + Number(this.amendmentAmount);

		const hasNegativeEquity = transactionScenarios[i]?.hasNegativeEquity;
		const negativeEquityMultiplier = hasNegativeEquity ? 0.75 : 2;

		const financeMin = hasNegativeEquity ? Number(this.initialOfferAmount) : 0;
		const financeMax = Math.round(Number(this.initialOfferAmount) / negativeEquityMultiplier);
		const financeAmount = transactionScenarios[i]?.hasFinance
			? datatype.number({ max: financeMax, min: financeMin })
			: 0;

		const sellerAmount = vehicleAmount - financeAmount;
		const brokerFeeAmount = transactionScenarios[i]?.hasBrokerFee ? datatype.number({ max: 400, min: 200 }) : 0;
		const transportFeeAmount = transactionScenarios[i]?.hasTransportFee ? datatype.number({ max: 200, min: 100 }) : 0;
		const dealerAmount = vehicleAmount + brokerFeeAmount + transportFeeAmount;

		const breakdown: PriceBreakdown[] = [
			createBreakdown(vehicleAmount, 'vehicle_amount'),
			createBreakdown(financeAmount, 'financing_amount'),
			createBreakdown(sellerAmount, 'seller_amount'),
			createBreakdown(brokerFeeAmount, 'broker_fee_amount'),
			createBreakdown(transportFeeAmount, 'transport_fee_amount'),
			createBreakdown(brokerFeeAmount + transportFeeAmount, 'total_fee_amount'),
			createBreakdown(dealerAmount, 'dealer_amount'),
		];

		return breakdown;
	},
	reference() {
		return '';
	},
	transactionDate() {
		return dayjs(date.past(5, new Date(2022, 0, 1))).toISOString();
	},
});

export default transactionFactory;
