import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const payments = (server: MirageServer): void => {
	server.post(`${API_ROUTES.PAYMENTS}/checkFunds`, () => {
		return { hasSufficientFunds: true, status: 'success' };
	});

	server.get(`${API_ROUTES.PAYMENTS}/payments`, () => {
		return {};
	});
};

export default payments;
