import { Response } from 'miragejs';

import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const initProcessRoutes = (server: MirageServer): void => {
	server.put(`${API_ROUTES.PAYMENTS}/v1/process`, () => {
		return new Response(200);
	});
};

export default initProcessRoutes;
