import T from 'i18n-react';
import sortBy from 'lodash/sortBy';

import { TransportDrivenIcon, TransportHeavyIcon, TransportTransportedIcon } from '@motorway/motorway-storybook-cra';

import texts from 'Components/texts.json';
import { AccountCategory, AccountSlugs } from 'Types/account';
import { gbpCurrencyFormatter } from 'Utilities/formatters';
import ROUTES from 'Utilities/routes';

import type { SiteTransportOption } from './ModalDeliveryLocation/ModalDeliveryLocation.types';
import type {
	GetOptionsParams,
	OptionItem,
	PrimarySiteDetails,
	SiteAddress,
	TransportOptionParams,
	TransportSelectionType,
	UsePurchasesButtonPropsParams,
	UsePurchasesButtonPropsReturn,
} from './Delivery.helpers.types';

T.setTexts(texts);

export const DELIVERY_NO_TRANSPORT = 'no-delivery';

export const TRANSPORT_TYPES = Object.freeze({
	driven: 'driven',
	heavyTransported: 'heavy-transported',
	transported: 'transported',
});

export const options = Object.freeze([
	{ icon: TransportDrivenIcon, type: TRANSPORT_TYPES.driven },
	{ icon: TransportTransportedIcon, type: TRANSPORT_TYPES.transported },
	{ icon: TransportHeavyIcon, type: TRANSPORT_TYPES.heavyTransported },
]);

export const getItem = (id: string | undefined, values: readonly OptionItem[] = options): OptionItem | undefined =>
	values?.find((item) => item?.type?.toLowerCase() === id?.toLowerCase());

const getIsNoAvailableText = (isMobile?: Nullable<boolean>) =>
	isMobile ? T.translate('delivery.notAvailable-mobile') : T.translate('delivery.notAvailable');

export const getOption = ({ isMobile, price, type }: TransportOptionParams): TransportSelectionType => ({
	disabled: !price,
	icon: getItem(type?.toLowerCase())?.icon,
	input: {
		id: type,
		name: 'radioIcon',
		value: type,
	},
	label: {
		footer: price ? `${gbpCurrencyFormatter().format(price)} +VAT` : String(getIsNoAvailableText(isMobile)),
		header: String(T.translate(`delivery.options.${type?.toLowerCase()}`)),
	},
	price,
});

export const getOptions = ({ isMobile, transportOptions }: GetOptionsParams): TransportSelectionType[] =>
	sortBy(
		options.map((option) => {
			const item = getItem(option.type, transportOptions as readonly OptionItem[]);
			return item
				? getOption({ ...item, isMobile } as TransportOptionParams)
				: getOption({ ...option, isMobile } as unknown as TransportOptionParams);
		}),
		['price', 'disabled'],
	);

export const usePurchasesButtonProps = ({
	enquiryId,
}: UsePurchasesButtonPropsParams): UsePurchasesButtonPropsReturn => {
	const buttonAs = ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.COMPLETE);

	return {
		buttonAs,
		buttonHref: buttonAs,
		buttonLabel: 'delivery.backToPurchases',
		gaParams: {
			action: 'Purchases button clicked',
			event_action: 'Purchases button clicked',
			event_category: 'Vehicle delivery',
			event_label: enquiryId,
		},
	};
};

export const getPrimarySiteDetails = (transport: SiteTransportOption[] = []): PrimarySiteDetails => {
	const defaultReturn = { dealer: {} as SiteAddress, isFerryRequired: false, siteId: '', transportOptions: [] };

	if (!Array.isArray(transport) || transport.length === 0) {
		return defaultReturn;
	}

	const primaryOrFirstOption = transport.find((option) => option.site?.isPrimary) || transport[0];

	if (primaryOrFirstOption) {
		const { address1, address2 = '', address3 = '', city, postcode } = primaryOrFirstOption.site.siteAddress;
		const { siteId } = primaryOrFirstOption.site;
		const isFerryRequired = primaryOrFirstOption.requiresFerry;
		const name = primaryOrFirstOption.site.siteName;
		const { transportOptions } = primaryOrFirstOption;
		return {
			dealer: { address1, address2, address3, city, name, postcode, siteId },
			isFerryRequired,
			siteId,
			transportOptions,
		};
	}

	return defaultReturn;
};
