import isChromatic from 'chromatic/isChromatic';
import { random } from 'faker';

import { TRANSPORT_TYPES } from 'Components/pages/Delivery/Delivery.helpers';
import { VEHICLE_FUEL_TYPES } from 'Utilities/consts';
import { API_ROUTES } from 'Utilities/routes';

const config = {
	driven: [
		{
			price: 999,
			type: TRANSPORT_TYPES.transported,
		},
		{
			price: 800,
			type: TRANSPORT_TYPES.driven,
		},
	],
	heavy: [
		{
			price: 1444,
			type: TRANSPORT_TYPES.heavyTransported,
		},
	],
	transported: [
		{
			price: 999,
			type: TRANSPORT_TYPES.transported,
		},
	],
};

const getTransportOptionsResponse = (vehicle = {}) => {
	const { attrs: { fuel } = {}, mirageMockData } = vehicle;

	if (mirageMockData?.transport?.isError) {
		return undefined;
	}

	const drivingDistance = 50.58;
	const requiresFerry = Boolean(mirageMockData?.transport?.ferry);
	const isElectric = fuel === VEHICLE_FUEL_TYPES.ELECTRIC;
	const isHeavy = mirageMockData?.transport?.type === TRANSPORT_TYPES.heavyTransported;
	const transportOptions = (isHeavy && config.heavy) || (isElectric && config.transported) || config.driven;

	return { drivingDistance, requiresFerry, transportOptions };
};

const deliveryRoutes = (server) => {
	server.get(API_ROUTES.TRANSPORT_OPTIONS, (schema, request) => {
		const vehicle = schema?.vehicles?.findBy({ id: request?.params?.enquiryId });

		return getTransportOptionsResponse(vehicle);
	});

	server.get(API_ROUTES.TRANSPORT, () => {
		const randomDeliveryType = isChromatic() ? Object.values(config)[0] : random.arrayElement(Object.values(config));
		const randomDeliveryOption = isChromatic() ? randomDeliveryType[0] : random.arrayElement(randomDeliveryType);

		return {
			...randomDeliveryOption,
		};
	});
};

export default deliveryRoutes;
