import getConfig from 'next/config';

import { ZENDESK_WIDGET_VISIBILITY_EVENT } from './ZendeskWidget.consts';

const { publicRuntimeConfig } = getConfig();

const { ZENDESK_SNOWPLOW_SESSION_FIELD_ID, ZENDESK_WIDGET_SNIPPET_KEY } = publicRuntimeConfig;

export const ZendeskKey = ZENDESK_WIDGET_SNIPPET_KEY;

const triggerZendeskWidgetVisibilityEvent = (showWidget: boolean) => {
	const event = new CustomEvent(ZENDESK_WIDGET_VISIBILITY_EVENT, {
		detail: {
			showWidget,
		},
	});
	window.dispatchEvent(event);
};

export const handleZendeskWidgetVisibility = (action: 'show' | 'hide') => {
	if (!window?.zE) return;

	window.zE('messenger', action);
	triggerZendeskWidgetVisibilityEvent(action === 'show');
};

export const handleZendeskWidgetLogin = (token: string) => {
	if (!window?.zE) return;

	window.zE('messenger', 'loginUser', (callback) => {
		callback(token);
	});
};

export const handleZendeskWidgetLogout = () => {
	if (!window?.zE) return;

	window.zE('messenger', 'logoutUser');
};

export const handleZendeskWidgetZIndex = (index: number) => {
	if (!window?.zE) return;

	window.zE('messenger:set', 'zIndex', index);
};

export const handleZendeskUnreadMessages = (callback: (count: number) => void) => {
	if (!window?.zE) return;

	window.zE('messenger:on', 'unreadMessages', (count) => {
		if (!count) {
			callback(0);
			return;
		}

		callback(count);
	});
};

export const handleZendeskOpenCloseStateChange = (callback: (isOpen: boolean) => void) => {
	if (!window?.zE) return;

	window.zE('messenger:on', 'open', () => {
		callback(true);
	});

	window.zE('messenger:on', 'close', () => {
		callback(false);
	});
};

export const triggerZendeskOpenCloseState = (action: 'open' | 'close') => {
	if (!window?.zE) return;

	window.zE('messenger', action);
};

export const setSnowplowSessionField = (sessionId: string) => {
	if (!window?.zE) return;
	window.zE('messenger:set', 'conversationFields', [
		{
			id: ZENDESK_SNOWPLOW_SESSION_FIELD_ID,
			value: sessionId,
		},
	]);
};
