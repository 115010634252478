import type { Request, Server } from 'miragejs';

import type { AppSchema } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

export const transactionRoutes = (server: Server): void => {
	server.get(`${API_ROUTES.PAYMENTS}/transactions/:id`, (schema: AppSchema, request: Request) => {
		const transactionId = request?.params?.id;
		const transaction = schema.findBy('transactions', { id: transactionId.toString() });
		const vehicle = schema.findBy('vehicle', ({ makeModel: { id } }) => id === Number(transactionId));

		return {
			data: {
				...transaction?.attrs,
				reference: `${vehicle?.vrm}-${transactionId}`,
			},
		};
	});

	server.get(`${API_ROUTES.PAYMENTS}/transactions`, (schema: AppSchema) => {
		const transactions = schema.all('transactions');
		const vehicles = schema.all('vehicle');

		return {
			data: [
				// Map the vehicle to the transaction
				...transactions.models.map(({ attrs }, index) => {
					const vehicle = vehicles.models[index].attrs;
					const amount = attrs.priceBreakdown.find(({ type }) => type === 'dealer_amount')?.amount;
					return {
						amount: -1 * (amount || 0),
						enquiryId: vehicle.id,
						id: vehicle.id,
						reference: `${vehicle.vrm}-${vehicle.id}`,
						transactionDate: attrs.transactionDate,
						vrm: vehicle.vrm,
					};
				}),
			],
			meta: {
				itemsCount: transactions.models.length,
				itemsPerPage: 25,
				pageNumber: 1,
				pages: 1,
				totalItemsCount: transactions.models.length,
			},
			status: 'success',
		};
	});

	server.get(`${API_ROUTES.PAYMENTS}/paymentAccounts`, () => ({
		data: [
			{
				balance: 100000.41,
				balanceFormatted: '100000.41',
				createdAt: '2023-07-07T10:23:03.079Z',
				id: 123,
				ownerId: 123,
				scan: {
					accountName: 'Motor Way',
					accountNumber: '12345678',
					sortCode: '000000',
				},
			},
		],
		status: 'success',
	}));
};
