/**
 * By default mirage uses ids of type string.
 * This uses the custom id manager feature of miragejs
 * to convert id to numbers.
 */
class IntegerIDManager {
	ids: Set<number>;

	nextId: number;

	constructor() {
		this.ids = new Set();
		this.nextId = 1;
	}

	fetch(): number {
		const id = this.nextId++;
		this.set(id);

		return id;
	}

	set(id: number): void {
		if (this.ids.has(id)) {
			throw new Error(`ID ${id} has already been used.`);
		}

		this.ids.add(id);
	}

	reset(): void {
		this.ids.clear();
	}
}

export default IntegerIDManager;
