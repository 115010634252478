import type { MirageServer } from 'Mirage/types';
import type { GetPaymentOptionsResponse } from 'Services/motorway/services/paymentOptions/paymentOptions.types';
import { API_ROUTES } from 'Utilities/routes';

import paymentOptionsFixtureData from '../../../fixtures/payments/commonFixtures/paymentOptionsMultiple.json';

export type PaymentOptionsResponseMock = {
	data: GetPaymentOptionsResponse;
	status: string;
};

const paymentOptionsRoutes = (server: MirageServer): void => {
	server.post(API_ROUTES.PAYMENT_OPTIONS, (): PaymentOptionsResponseMock => {
		return paymentOptionsFixtureData;
	});
};

export default paymentOptionsRoutes;
