import type { StateSlug } from 'Types/enquiryStates';
import { ForSaleCategory } from 'Types/enquiryStates';

import { STATE_SLUG_TO_LISTINGS_TABLE_SALE_TYPE } from './AdditionalVehicleDetailsStore.const';

export const mapStateSlugToListingsTableSaleType = (slug: StateSlug) => {
	const isValidState = slug === ForSaleCategory.forSale || slug === ForSaleCategory.saleActive;

	return isValidState ? STATE_SLUG_TO_LISTINGS_TABLE_SALE_TYPE[slug] : undefined;
};
