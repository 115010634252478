import Cookies from 'js-cookie';
import { Response } from 'miragejs';

import { API_ROUTES } from 'Utilities/routes';

const RESPONSE_TYPES = {
	ADD_500: 'ADD_500',
	ADD_MAX: 'ADD_MAX',
	DEL_500: 'DEL_500',
	GET_500: 'GET_500',
};

const maxFilters = 4;

const getSavedFilters = ({ schema }) => {
	const filters = schema.savedFilters.all().models;

	return {
		filters,
		maxFilters,
		totalCount: filters.length,
	};
};

const createSavedFilter = ({ request, server }) => {
	const payload = JSON.parse(request.requestBody);

	const { filterData, name = '', sendEmailAlert = false } = payload;

	const now = new Date();

	const totalCount = Number(server?.db?.savedFilters?.length) + 1;

	const filter = server.db.savedFilters.insert({
		createdAt: now.toISOString(),
		filterData,
		name,
		sendEmailAlert,
	});

	return { filter, totalCount };
};

const updateSavedFilter = ({ request, server }) => {
	const { id, ...payload } = JSON.parse(request.requestBody);

	server.db.savedFilters.update({ id }, payload);

	return {
		id,
		...payload,
	};
};

const deleteSavedFilter = ({ request, server }) => {
	const { id } = request.params;
	return server.db.savedFilters.remove({ id });
};

const getMode = () => Cookies.get('savedFiltersMirage');

const savedFilters = (server) => {
	server.get(`${API_ROUTES.SAVED_SEARCHES}`, (schema) => {
		const MODE = getMode();
		switch (MODE) {
			case RESPONSE_TYPES.GET_500:
				return new Response(500);
			default:
				return getSavedFilters({ schema });
		}
	});

	server.post(`${API_ROUTES.SAVED_SEARCHES_CREATE}`, (schema, request) => {
		const MODE = getMode();
		switch (MODE) {
			case RESPONSE_TYPES.ADD_500:
				return new Response(500);
			case RESPONSE_TYPES.ADD_MAX:
				return new Response(422);
			default:
				return createSavedFilter({ request, server });
		}
	});

	server.post(`${API_ROUTES.SAVED_SEARCHES_UPDATE}`, (schema, request) => {
		const MODE = getMode();
		switch (MODE) {
			default:
				return updateSavedFilter({ request, server });
		}
	});

	server.post(`${API_ROUTES.SAVED_SEARCHES_DELETE}`, (schema, request) => {
		const MODE = getMode();
		switch (MODE) {
			case RESPONSE_TYPES.DEL_500:
				return new Response(500);
			default:
				return deleteSavedFilter({ request, server });
		}
	});
};

export default savedFilters;
