import useVehicleFilterStore, {
	useHasUserSetSort,
	useSetHasUserSetSort,
} from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useListType } from 'Stores/ListStore/ListStore';
import { LIST_TYPES } from 'Utilities/consts';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import { VEHICLE_SORT_OPTIONS_BIN_EXTENDED, VEHICLE_SORT_ORDER } from './useSortOrder.const';
import { getOrderFromSort, isValidOrderParamValue, isValidSortParamValue } from './useSortOrder.helpers';
import type { SortOption, UseSortOrder } from './useSortOrder.types';

const useSortOptions = (): readonly SortOption[] => {
	const listType = useListType();
	const isEndingSoonSortEnabled = useFeatureToggle(FEATURES.binEndingSoonSort);

	return isEndingSoonSortEnabled && listType === LIST_TYPES.buyItNow
		? VEHICLE_SORT_OPTIONS_BIN_EXTENDED
		: VEHICLE_SORT_ORDER[listType];
};

const useSortOrder = (): UseSortOrder => {
	const { order, sort } = useVehicleFilterStore((store) => store.filters);
	const hasUserSetSort = useHasUserSetSort();
	const setHasUserSetSort = useSetHasUserSetSort();
	const sortOptions = useSortOptions();

	const defaultSort = sortOptions[0].sort;
	const defaultOrder = sortOptions[0].order;

	// Gets a valid combination of sort/order and checks against flags
	const isValidSort = isValidSortParamValue({ sort, sortOptions });
	const isValidOrder = isValidOrderParamValue({ adjustedSort: sort, order, sortOptions });
	let adjustedSort;
	let adjustedOrder;

	// To persist first sort option when user hasn't selected a sort option yet
	if (!hasUserSetSort) {
		adjustedSort = defaultSort;
		adjustedOrder = defaultOrder;
	} else if (isValidSort) {
		adjustedSort = sort;
		adjustedOrder = isValidOrder ? order : getOrderFromSort({ adjustedSort, sortOptions });
	} else {
		// Invalid sort provided
		setHasUserSetSort(false);
		adjustedSort = defaultSort;
		adjustedOrder = defaultOrder;
	}

	return { defaultOrder, defaultSort, order: adjustedOrder, sort: adjustedSort, sortOptions };
};

export default useSortOrder;
