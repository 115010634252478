export const transactionScenarios = [
	// No fees
	{
		hasBrokerFee: false,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: false,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: false,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: false,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: false,
	},
	// Motorway & transport fee
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: true,
	},
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: true,
	},
	{
		hasBrokerFee: true,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: true,
	},
	{
		hasBrokerFee: true,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: true,
	},
	// Motorway fee
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: true,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: true,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: true,
		hasFinance: false,
		hasNegativeEquity: false,
		hasPriceReduction: false,
		hasTransportFee: false,
	},
	// Negative equity
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: true,
		hasPriceReduction: true,
		hasTransportFee: false,
	},
	{
		hasBrokerFee: true,
		hasFinance: true,
		hasNegativeEquity: true,
		hasPriceReduction: false,
		hasTransportFee: false,
	},
];
