import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import { AccountSlugs } from 'Types/account';

const { ACCOUNT } = ANALYTICS_CATEGORIES;

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerPurchasesCompletePurchaseSummaryButtonClickedEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: ACCOUNT[AccountSlugs.COMPLETE],
				name: `dlr_purchases_complete_purchase_summary_button_clicked`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
