import { API_ROUTES } from 'Utilities/routes';

const bidRoutes = (server) => {
	server.post(`${API_ROUTES.BIDS}`, (_, request) => {
		const { enquiryId } = JSON.parse(request.requestBody);
		return {
			id: parseInt(enquiryId),
		};
	}); // Mock request to add a bid to prevent bidding API errors
	server.post(`${API_ROUTES.BIDS_CANCEL}`, () => null);
};

export default bidRoutes;
