import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

import type { GalleryEvent } from '../galleryEventsRedesign/types';

const { PURCHASE_SUMMARY_GALLERY } = ANALYTICS_CATEGORIES;
const { SNOWPLOW } = ANALYTICS_PROVIDERS;
const { CLICK } = SNOWPLOW_EVENTS;

export const triggerKeyDocumentsGalleryClosedEvent = ({ kind }: GalleryEvent): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: PURCHASE_SUMMARY_GALLERY,
				customData: { label: kind },
				name: `dlr_key_documents_gallery_closed`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
