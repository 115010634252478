import type { InsightsRecentSoldData } from 'Services/motorway/services/insights/insights.types';

const recentSoldFixtureData: InsightsRecentSoldData = {
	averageSoldPriceInLastYear: 2500,
	highestSoldPriceInLastYear: 5000,
	lastUpdatedAt: '2024-03-18',
	lowestSoldPriceInLastYear: 1000,
	vehicles: [
		{
			derivative: 'Stradale',
			enquiryId: 1,
			mileage: 35000,
			serviceHistory: 'partial',
			soldDate: '2018-04-25',
			soldPrice: 12000,
		},
		{
			derivative: 'Cabriolet',
			enquiryId: 2,
			manufactureYear: 0,
			mileage: 45000,
			soldDate: '2017-02-15',
			soldPrice: 10000,
		},
		{
			derivative: 'Speciale',
			enquiryId: 3,
			manufactureYear: 2014,
			serviceHistory: '',
			soldDate: '2017-06-22',
			soldPrice: 15000,
		},
		{
			derivative: 'Modena',
			enquiryId: 4,
			manufactureYear: 2016,
			mileage: 0,
			serviceHistory: 'full',
			soldPrice: 10000,
		},
		{
			derivative: 'Spyder',
			enquiryId: 5,
			manufactureYear: 2017,
			mileage: 13000,
			serviceHistory: 'no',
			soldDate: '',
		},
		{
			derivative: 'Type R',
			enquiryId: 6,
			manufactureYear: 2018,
			mileage: 120000,
			serviceHistory: 'not_applicable',
			soldDate: '2019-09-20',
			soldPrice: 0,
		},
		{
			enquiryId: 7,
			manufactureYear: 2012,
			mileage: 123450,
			serviceHistory: 'skipped',
			soldDate: '2024-01-31',
			soldPrice: 1001,
		},
		{
			derivative: '',
			enquiryId: 8,
			manufactureYear: 2010,
			mileage: 99999,
			serviceHistory: 'not_applicable',
			soldDate: '2023-12-05',
			soldPrice: 1456,
		},
		{
			derivative: 'VTR',
			enquiryId: 9,
			manufactureYear: 2019,
			mileage: 43000,
			serviceHistory: 'yes',
			soldDate: '2023-10-16',
			soldPrice: 6350,
		},
		{
			derivative: 'VTEC',
			enquiryId: 10,
			manufactureYear: 2012,
			mileage: 11111,
			serviceHistory: 'yes',
			soldDate: '2024-04-20',
			soldPrice: 1111,
		},
		{
			derivative: 'VTEC',
			enquiryId: 11,
			manufactureYear: 2013,
			mileage: 22222,
			serviceHistory: 'partial',
			soldDate: '2024-04-21',
			soldPrice: 2222,
		},
		{
			derivative: 'VTEC',
			enquiryId: 12,
			manufactureYear: 2014,
			mileage: 33333,
			serviceHistory: 'partial',
			soldDate: '2024-04-22',
			soldPrice: 3333,
		},
		{
			derivative: 'VTEC',
			enquiryId: 13,
			manufactureYear: 2015,
			mileage: 44444,
			serviceHistory: 'yes',
			soldDate: '2024-04-23',
			soldPrice: 4444,
		},
	],
	vehiclesSold: 55,
};

export default recentSoldFixtureData;
