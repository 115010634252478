import { pinoInstance } from './logger';
import type { Logger } from './logger.types';

// NOTE: This should not be used directly. Use the logger instead.

export const pinoLogger: Logger = {
	critical: (message, context): void =>
		pinoInstance.logger?.critical?.(context, message) || pinoInstance.logger.fatal(context, message),
	debug: (message, context): void => pinoInstance.logger.debug(context, message),
	error: (message, context): void => pinoInstance.logger.error(context, message),
	fatal: (message, context): void => pinoInstance.logger.fatal(context, message),
	info: (message, context): void => pinoInstance.logger.info(context, message),
	trace: (message, context): void => pinoInstance.logger.trace(context, message),
	warn: (message, context): void => pinoInstance.logger.warn(context, message),
};
