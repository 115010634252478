import { LIST_TYPES } from 'Utilities/consts';

const VEHICLE_SORT_OPTIONS = [
	{
		name: 'nearestTo',
		order: 'ASC',
		sort: 'distance',
		value: 'distance-ASC',
	},
	{
		name: 'displayPriceL',
		order: 'ASC',
		sort: 'displayPrice',
		value: 'displayPrice-ASC',
	},
	{
		name: 'displayPriceH',
		order: 'DESC',
		sort: 'displayPrice',
		value: 'displayPrice-DESC',
	},
	{
		name: 'mileagePriceL',
		order: 'ASC',
		sort: 'mileage',
		value: 'mileage-ASC',
	},
	{
		name: 'mileagePriceH',
		order: 'DESC',
		sort: 'mileage',
		value: 'mileage-DESC',
	},
	{
		name: 'yearN',
		order: 'DESC',
		sort: 'spec.firstRegistered',
		value: 'spec.firstRegistered-DESC',
	},
	{
		name: 'yearO',
		order: 'ASC',
		sort: 'spec.firstRegistered',
		value: 'spec.firstRegistered-ASC',
	},
] as const;

const forSaleDateDescSortOption = {
	name: 'forSaleDateDesc',
	order: 'DESC',
	sort: 'state.name,forSaleDate',
	value: 'state.name,forSaleDate-DESC',
};

export const VEHICLE_SORT_OPTIONS_BIN = [forSaleDateDescSortOption, ...VEHICLE_SORT_OPTIONS];

export const VEHICLE_SORT_OPTIONS_SHORTLIST = [forSaleDateDescSortOption, ...VEHICLE_SORT_OPTIONS];

export const VEHICLE_SORT_OPTIONS_BIN_EXTENDED = [
	{
		name: 'forSaleDateAsc',
		order: 'ASC',
		sort: 'state.name,forSaleDate',
		value: 'state.name,forSaleDate-ASC',
	},
	...VEHICLE_SORT_OPTIONS_BIN,
];

export const VEHICLE_SORT_OPTIONS_LIVE_SALE = [
	{
		name: 'recommended',
		order: 'DESC',
		sort: 'recommended',
		value: 'recommended-DESC',
	},
	...VEHICLE_SORT_OPTIONS,
	{
		name: 'bidCountsL',
		order: 'ASC',
		sort: 'bidCounts',
		value: 'bidCounts-ASC',
	},
	{
		name: 'bidCountsH',
		order: 'DESC',
		sort: 'bidCounts',
		value: 'bidCounts-DESC',
	},
] as const;

export const VEHICLE_SORT_ORDER = {
	[LIST_TYPES.auction]: VEHICLE_SORT_OPTIONS_LIVE_SALE,
	[LIST_TYPES.buyItNow]: VEHICLE_SORT_OPTIONS_BIN,
	[LIST_TYPES.shortlist]: VEHICLE_SORT_OPTIONS_SHORTLIST,
};

export const VEHICLE_SORT_OPTIONS_SORT_ONLY = [
	'recommended',
	'state.name,forSaleDate',
	'distance',
	'displayPrice',
	'mileage',
	'spec.firstRegistered',
	'bidCounts',
] as const;

export const VEHICLE_SORT_CONDITIONAL_OPTIONS = { bidCounts: 'bidCounts', recommended: 'recommended' };
