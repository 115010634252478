import dayjs from 'dayjs';
import sortBy from 'lodash/sortBy';
import { Response } from 'miragejs';

import { SELLER_TYPES, VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { ADDITIONAL_VEHICLE_DETAILS_MOCK } from 'Mirage/scenarios/additionalVehicleDetails';
import { getMockSaleTimes } from 'Mirage/utilities/sale.helpers';
import { RESULTS_PER_PAGE } from 'Services/dataProvider.const';
import { stringBooleanToBoolean } from 'Utilities/helpers';
import { getQueryValuesFromUrlByKey } from 'Utilities/helpers/getQueryValuesFromUrlByKey';
import { API_ROUTES } from 'Utilities/routes';

import {
	matchAgeRange,
	matchCancelled,
	matchesListType,
	matchesMake,
	matchesMileageRange,
	matchesModel,
	matchesPriceRange,
	matchesSold,
	matchFuelType,
	matchPreviousOwners,
	matchServiceHistory,
	matchTransmission,
	matchUnderOffer,
	matchVehicleClass,
	matchVehicleGrade,
	resolve,
} from '../utilities/filterResolvers';

import {
	getValidVehicleStatesForSaleTime,
	MIRAGE_DEFAULT_EMPTY_VEHICLE_LIST_RESPONSE,
	MIRAGE_VEHICLE_LIST_CSV_RESPONSE,
	shouldShowVehiclesForSaleTime,
} from './vehicle.helpers';

const filterVehiclesByState = ({ states, vehicles }) =>
	vehicles?.filter((vehicle) => states?.includes(vehicle?.state?.slug));

const filterVehiclesByMatchers = ({ request, schema, state }) => {
	const matchers = [
		matchesSold,
		matchUnderOffer,
		matchCancelled,
		matchesPriceRange,
		matchesMileageRange,
		matchesListType,
		matchVehicleGrade,
		matchesMake,
		matchesModel,
		matchFuelType,
		matchServiceHistory,
		matchTransmission,
		matchVehicleClass,
		matchAgeRange,
		matchPreviousOwners,
	];

	return schema.vehicles.where((vehicle) => resolve(...matchers)({ request, schema, state, vehicle }));
};

const getMirageVehicleListResponse = ({ request, schema, state, validVehicleStates }) => {
	const vehiclesCollection = filterVehiclesByMatchers({ request, schema, state });
	const vehicles = validVehicleStates
		? filterVehiclesByState({ states: validVehicleStates, vehicles: vehiclesCollection.models })
		: vehiclesCollection.models;
	const totalCount = vehicles.length;
	const page = parseInt(request.queryParams.page) || 0;
	const pageStart = page * RESULTS_PER_PAGE;
	const pageEnd = (page + 1) * RESULTS_PER_PAGE;
	const timeId = dayjs('2023-12-12T15:00:00.770Z').unix();

	return {
		hasMore: pageEnd < totalCount,
		timeId,
		totalCount,
		vehicles: vehicles.slice(pageStart, pageEnd),
	};
};

export const getMirageTimeSensitiveVehicleListResponse = ({ request, schema, state }) => {
	const saleTimes = getMockSaleTimes();
	const listType = request?.queryParams?.listType;
	const saleTimeArgs = { listType, saleTimes };
	const shouldShowVehicles = shouldShowVehiclesForSaleTime(saleTimeArgs);
	const validVehicleStates = getValidVehicleStatesForSaleTime({ ...saleTimeArgs, saleStartTolerance: 0 });

	if (!shouldShowVehicles) {
		return MIRAGE_DEFAULT_EMPTY_VEHICLE_LIST_RESPONSE;
	}

	return getMirageVehicleListResponse({ request, schema, state, validVehicleStates });
};

export const filterVehicleListResponseBySellerType = ({ request, response }) => {
	const sellerType = getQueryValuesFromUrlByKey(request.url, VEHICLE_LIST_FILTER_NAME.SELLER_TYPE);
	const isTradeIncludedQuery = sellerType.includes(SELLER_TYPES.TRADE);
	const isPrivateIncludedQuery = sellerType.includes(SELLER_TYPES.PRIVATE);
	const isQueryingAllSellerTypes = isTradeIncludedQuery && isPrivateIncludedQuery;
	const isEmptyQuery = sellerType.length === 0;
	const shouldReturnAllVehicles = isQueryingAllSellerTypes || isEmptyQuery;

	if (shouldReturnAllVehicles) {
		return response;
	}

	const vehicles = response.vehicles.filter(({ isTrade }) => (isTradeIncludedQuery ? isTrade : !isTrade));

	return {
		...response,
		totalCount: vehicles.length,
		vehicles,
	};
};

const vehicleRoutes = (server) => {
	server.get(API_ROUTES.VEHICLE, (schema, request) => {
		const isSchemaForList = stringBooleanToBoolean(request?.queryParams?.isSchemaForList);
		const vehicleId = request?.queryParams?.vehicleId;
		const vehicle = schema.vehicles.findBy({ id: vehicleId });

		// isSchemaForList returns the vehicle in an array.
		const response = isSchemaForList ? [vehicle?.attrs] : vehicle?.attrs;
		return vehicle?.attrs ? response : null;
	});

	server.get(API_ROUTES.VEHICLE_LIST, (schema, request, state) => {
		const result = getMirageVehicleListResponse({ request, schema, state });
		return result;
	});

	server.get(API_ROUTES.MAKES, (schema) => sortBy(schema.makes.all().models, ['slug']));

	server.get(API_ROUTES.SIMILAR_VEHICLES, (schema, request) => {
		const vehicles = schema.vehicles.all()?.models?.map((vehicle) => vehicle.attrs);
		const vehicleId = parseInt(request.queryParams.enquiryId);
		// Use vehicleId to drive the logic on how many vehicles should be returned.
		const similarVehicles = vehicles?.slice(0, vehicleId - 1);

		return {
			// The last vehicle on the list with id 12 is the one that returns this flag as true
			shouldUseMakeAndModelFilter: vehicleId === 12,
			similarVehicles,
			similarVehiclesInLiveSale: similarVehicles.length,
		};
	});

	server.get(API_ROUTES.SIMILAR_VEHICLES_BY_MAKE_AND_MODEL, (schema) => {
		const vehicles = schema.vehicles.all()?.models?.map((vehicle) => vehicle.attrs);
		const similarVehicles = vehicles?.slice(0, 9);

		return {
			shouldUseMakeAndModelFilter: true,
			similarVehicles,
			similarVehiclesInLiveSale: similarVehicles.length,
		};
	});

	server.post(API_ROUTES.GENERATE_VEHICLE_LIST_CSV, (schema) => {
		const timeToResolveMockVehicleListCsv = 4000;
		const timeout = schema.db.vehicleListCsvTimeout;

		if (timeout) {
			clearTimeout(timeout);
		}

		schema.db.vehicleListCsvTimeout = setTimeout(() => {
			schema.db.vehicleListCsvResponse = MIRAGE_VEHICLE_LIST_CSV_RESPONSE.success;
		}, timeToResolveMockVehicleListCsv);

		schema.db.vehicleListCsvResponse = MIRAGE_VEHICLE_LIST_CSV_RESPONSE.processing;

		return new Response(200, {}, '');
	});

	server.get(API_ROUTES.GET_MARKETPLACE_VEHICLE_LIST_CSV, () => {
		return new Response(200, {}, MIRAGE_VEHICLE_LIST_CSV_RESPONSE.success.data);
	});

	server.get(API_ROUTES.GET_VEHICLE_LIST_CSV, (schema) => {
		const response = schema.db.vehicleListCsvResponse ?? {};
		const statusCode = response.statusCode || 404;

		if (statusCode === MIRAGE_VEHICLE_LIST_CSV_RESPONSE.success.statusCode) {
			// If successful, reset the state ready for the next call
			schema.db.vehicleListCsvResponse = MIRAGE_VEHICLE_LIST_CSV_RESPONSE.error;
		}

		return new Response(statusCode, {}, response.data);
	});

	server.get(API_ROUTES.GET_ADDITIONAL_VEHICLE_DETAILS, () => {
		return new Response(200, {}, ADDITIONAL_VEHICLE_DETAILS_MOCK);
	});
};

export default vehicleRoutes;
