import type { Request, Server } from 'miragejs';

import type { AppSchema, ShortlistedEnquiry } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

import {
	getShortlistedEnquiriesFromSchema,
	initialiseShortlistedEnquiries,
	removeShortlistedEnquiriesFromDB,
} from './shortlist.helpers';

export const shortlistRoutes = (server: Server): void => {
	initialiseShortlistedEnquiries(server);

	server.get(API_ROUTES.SHORTLISTED_ENQUIRIES, (schema: AppSchema) => {
		const shortlistedEnquiryModels = schema.all('shortlistedEnquiry')?.models;
		return shortlistedEnquiryModels?.map(({ enquiryId, updatedAt }: ShortlistedEnquiry) => ({ enquiryId, updatedAt }));
	});

	server.post(API_ROUTES.SHORTLIST, (schema: AppSchema, request: Request) => {
		const { enquiryId } = request.params;
		const enquiry = { enquiryId: parseInt(enquiryId), updatedAt: new Date().toISOString() };

		schema.db.shortlistedEnquiries.insert(enquiry);

		return enquiry;
	});

	server.post(API_ROUTES.UNSHORTLIST, (schema: AppSchema, request: Request) => {
		const vehicle = schema.findBy('vehicle', ({ id }) => id === Number(request.params?.enquiryId));
		const enquiry = schema.findBy('shortlistedEnquiry', ({ enquiryId }) => enquiryId === vehicle?.id)?.attrs;
		const { enquiryId, updatedAt } = enquiry || {};

		schema.db.shortlistedEnquiries.remove({ enquiryId });

		return { enquiryId, updatedAt };
	});

	server.post(
		API_ROUTES.UNSHORTLIST_ENQUIRIES,
		(schema: AppSchema, request: Request) => {
			const { enquiryIds } = JSON.parse(request.requestBody);
			const data = getShortlistedEnquiriesFromSchema({ enquiryIds, schema });

			removeShortlistedEnquiriesFromDB({ enquiryIds, schema });

			return { data, status: 'success' };
		},
		{ timing: 0 }, // Prevent delay to ensure it runs before the page is reloaded
	);
};
