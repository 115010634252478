import { Response } from 'miragejs';

import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const initOTPRoutes = (server: MirageServer): void => {
	server.post(`${API_ROUTES.PAYMENTS}/otp/generate`, () => {
		return new Response(200);
	});
};

export default initOTPRoutes;
