import { MDText } from 'i18n-react';

import LocalTexts from '../components/texts.json';
import { VEHICLE_LIST_FILTER_NAME } from '../components/VehicleList/VehicleListFilters.consts';

import { LIST_TYPES } from './consts';
import { capitaliseSentence } from './helpers';

const LocalT = new MDText(LocalTexts);

// ============ STATES DECLARATION ==================================================
export const STATE_CATEGORIES = Object.freeze({
	CANCELLED: 'cancelled',
	FOR_SALE: 'for_sale',
	JUNK: 'junk',
	MOTORWAY_PRO: 'motorway_pro',
	PROFILED: 'profiled',
	QUOTED: 'quoted',
	REJECTED: 'rejected',
	SOLD: 'sold',
	UNSOLD: 'unsold',
});

export const PROFILED_CATEGORY = {
	FULL_DETAILS: 'full_details',
	FULL_DETAILS_CONTACTED: 'full_details_contacted',
	FULL_DETAILS_WAIT: 'full_details_wait',
	PART_DETAILS: 'part_details',
	PART_DETAILS_CONTACTED: 'part_details_contacted',
	QA_FAILED: 'qa_failed',
	QA_ON_HOLD: 'qa_on_hold',
	QA_READY: 'qa_ready',
};

export const FOR_SALE_CATEGORY = {
	FOR_SALE: 'for_sale',
	READY_FOR_SALE: 'ready_for_sale',
	SALE_ACTIVE: 'sale_active',
	UNDER_OFFER: 'under_offer',
};

export const SOLD_CATEGORY = {
	SOLD: 'sold',
	SOLD_CONFIRMED: 'sold_confirmed',
	SOLD_DOCUMENTS_REVIEWED: 'sold_documents_reviewed',
	SOLD_INVOICE_PAID: 'sold_invoice_paid',
	SOLD_INVOICE_RAISED: 'sold_invoice_raised',
	SOLD_PURCHASE_CONFIRMED: 'sold_purchase_confirmed',
	SOLD_WAIT: 'sold_wait',
};

export const UNSOLD_CATEGORY = {
	UNSOLD_BELOW_RESERVE_BID: 'unsold_below_reserve_bid',
	UNSOLD_NO_BIDS: 'unsold_no_bids',
};

export const CANCELLED_CATEGORY = {
	CANCELLED_DEALER_WITHDREW_BID: 'cancelled_dealer_withdrew_bid',
	CANCELLED_NOT_AS_DESCRIBED: 'cancelled_not_as_described',
	CANCELLED_NOT_COLLECTED_IN_TIME: 'cancelled_not_collected_in_time',
	CANCELLED_OTHER_DEALER_ISSUE: 'cancelled_other_dealer_issue',
	CANCELLED_OTHER_SELLER_ISSUE: 'cancelled_other_seller_issue',
	CANCELLED_SELLER_UNCONTACTABLE: 'cancelled_seller_uncontactable',
	CANCELLED_SOLD_ELSEWHERE: 'cancelled_sold_elsewhere',
};

export const REJECTED_CATEGORY = {
	CAR_GONE: 'car_gone',
	CUSTOMER_KEEP_CAR: 'customer_keep_car',
	CUSTOMER_LOST: 'customer_lost',
	TOO_EXPENSIVE: 'too_expensive',
	UNSELLABLE: 'unsellable',
	WRITE_OFF: 'write_off',
};

export const MOTORWAY_PRO_CATEGORY = {
	NO_LONGER_FOR_SALE: 'no_longer_for_sale',
	NOT_YET_FOR_SALE: 'not_yet_for_sale',
};

// Add readonly `name` property to each category to be able to match category with category :)
Object.defineProperty(PROFILED_CATEGORY, 'name', { value: STATE_CATEGORIES.PROFILED });
Object.defineProperty(FOR_SALE_CATEGORY, 'name', { value: STATE_CATEGORIES.FOR_SALE });
Object.defineProperty(SOLD_CATEGORY, 'name', { value: STATE_CATEGORIES.SOLD });
Object.defineProperty(UNSOLD_CATEGORY, 'name', { value: STATE_CATEGORIES.UNSOLD });
Object.defineProperty(CANCELLED_CATEGORY, 'name', { value: STATE_CATEGORIES.CANCELLED });
Object.defineProperty(REJECTED_CATEGORY, 'name', { value: STATE_CATEGORIES.REJECTED });
Object.defineProperty(MOTORWAY_PRO_CATEGORY, 'name', { value: STATE_CATEGORIES.MOTORWAY_PRO });

// Disallow slugs objects to be modified.
Object.freeze(PROFILED_CATEGORY);
Object.freeze(FOR_SALE_CATEGORY);
Object.freeze(SOLD_CATEGORY);
Object.freeze(UNSOLD_CATEGORY);
Object.freeze(CANCELLED_CATEGORY);
Object.freeze(REJECTED_CATEGORY);
Object.freeze(MOTORWAY_PRO_CATEGORY);

export const STATES = Object.freeze({
	...PROFILED_CATEGORY,
	...FOR_SALE_CATEGORY,
	...SOLD_CATEGORY,
	...UNSOLD_CATEGORY,
	...CANCELLED_CATEGORY,
	...REJECTED_CATEGORY,
	...MOTORWAY_PRO_CATEGORY,
});

export const STATES_BY_CATEGORIES = {
	CANCELLED_CATEGORY,
	FOR_SALE_CATEGORY,
	MOTORWAY_PRO_CATEGORY,
	PROFILED_CATEGORY,
	REJECTED_CATEGORY,
	SOLD_CATEGORY,
	UNSOLD_CATEGORY,
};

export const SLUGS = Object.freeze({
	cancelledDealerWithdrewBid: 'cancelled_dealer_withdrew_bid',
	cancelledNotAsDescribed: 'cancelled_not_as_described',
	cancelledNotCollectedInTime: 'cancelled_not_collected_in_time',
	cancelledOtherDealerIssue: 'cancelled_other_dealer_issue',
	cancelledOtherSellerIssue: 'cancelled_other_seller_issue',
	cancelledSellerUncontactable: 'cancelled_seller_uncontactable',
	cancelledSoldElsewhere: 'cancelled_sold_elsewhere',
	carGone: 'car_gone',
	customerKeepCar: 'customer_keep_car',
	customerLost: 'customer_lost',
	forSale: 'for_sale',
	fullDetailsAwaitingPhotos: 'full_details_wait',
	qaFailed: 'qa_failed',
	qaReady: 'qa_ready',
	readyForSale: 'ready_for_sale',
	saleActive: 'sale_active',
	sold: 'sold',
	soldAwaitingConfirmation: 'sold_wait',
	soldInvoicePaid: 'sold_invoice_paid',
	soldInvoiceRaised: 'sold_invoice_raised',
	soldPurchaseConfirmed: 'sold_purchase_confirmed',
	soldSellerConfirmed: 'sold_confirmed',
	tooExpensive: 'too_expensive',
	underOffer: 'under_offer',
	unsellable: 'unsellable',
	unsold_below_reserve_bid: 'unsold_below_reserve_bid',
	unsold_no_bids: 'unsold_no_bids',
	writeOff: 'write_off',
});

// ============ STATES AS ARRAY OF VALUES ===========================================

export const soldStates = Object.freeze(Object.values(SOLD_CATEGORY));

export const unsoldStates = Object.freeze(Object.values(UNSOLD_CATEGORY));

export const forSaleStates = Object.freeze(Object.values(FOR_SALE_CATEGORY));

export const profiledStates = Object.freeze(Object.values(PROFILED_CATEGORY));

export const cancelledStates = Object.freeze(Object.values(CANCELLED_CATEGORY));

export const rejectedStates = Object.freeze(Object.values(REJECTED_CATEGORY));

// ============ STATES MAPPERS ======================================================

export const noLongerAvailableCategories = Object.freeze([STATE_CATEGORIES.REJECTED, STATE_CATEGORIES.CANCELLED]);

export const noLongerForSaleStates = [...rejectedStates, ...cancelledStates];

export const notYetForSaleVehicleStates = [
	PROFILED_CATEGORY.FULL_DETAILS_WAIT,
	FOR_SALE_CATEGORY.READY_FOR_SALE,
	PROFILED_CATEGORY.QA_READY,
	PROFILED_CATEGORY.QA_FAILED,
	PROFILED_CATEGORY.QA_ON_HOLD,
];

export const availableStates = Object.freeze([
	FOR_SALE_CATEGORY.FOR_SALE,
	FOR_SALE_CATEGORY.UNDER_OFFER,
	UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID,
	FOR_SALE_CATEGORY.SALE_ACTIVE,
	UNSOLD_CATEGORY.UNSOLD_NO_BIDS,
]);

export const highestBidAvailableStates = Object.freeze([
	FOR_SALE_CATEGORY.FOR_SALE,
	FOR_SALE_CATEGORY.UNDER_OFFER,
	UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID,
]);

export const activeStatuses = Object.freeze([FOR_SALE_CATEGORY.SALE_ACTIVE]);

export const availableStatesForBIN = Object.freeze([
	FOR_SALE_CATEGORY.FOR_SALE,
	UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID,
]);

export const allowedStates = Object.freeze([...availableStates, ...soldStates, ...notYetForSaleVehicleStates]);

export const allowedVehicleStatesByFilter = Object.freeze({
	[VEHICLE_LIST_FILTER_NAME.INCLUDE_SOLD]: soldStates,
	[VEHICLE_LIST_FILTER_NAME.INCLUDE_UNDER_OFFER]: [
		FOR_SALE_CATEGORY.UNDER_OFFER,
		UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID,
	],
	[LIST_TYPES.auction]: [FOR_SALE_CATEGORY.SALE_ACTIVE],
	[LIST_TYPES.buyItNow]: [FOR_SALE_CATEGORY.FOR_SALE],
	[LIST_TYPES.shortlist]: [],
});

export const DOCUMENT_CAPTURE_ALLOWED_STATES = [
	SOLD_CATEGORY.SOLD_DOCUMENTS_REVIEWED,
	SOLD_CATEGORY.SOLD_INVOICE_RAISED,
];

export const DOCUMENT_CAPTURE_WITH_TIMELINE_STATES = [...DOCUMENT_CAPTURE_ALLOWED_STATES, SOLD_CATEGORY.SOLD_CONFIRMED];

export const DOCUMENT_CAPTURE_SOLD_PRE_APPROVAL_STATES = [
	SOLD_CATEGORY.SOLD,
	SOLD_CATEGORY.SOLD_CONFIRMED,
	SOLD_CATEGORY.SOLD_WAIT,
];

export const TRANSPORT_ALLOWED_STATES = [
	SOLD_CATEGORY.SOLD_CONFIRMED,
	SOLD_CATEGORY.SOLD_DOCUMENTS_REVIEWED,
	SOLD_CATEGORY.SOLD_WAIT, // supposed legacy status but we will still account for it
	SOLD_CATEGORY.SOLD_INVOICE_PAID, // supposed legacy status but we will still account for it
	SOLD_CATEGORY.SOLD_INVOICE_RAISED, // supposed legacy status but we will still account for it
];

// ============ STATES FUNCTIONS ====================================================

export const getVehicleCategoryFromVehicleSlug = (slug) => {
	const categoryKey = Object.keys(STATES_BY_CATEGORIES).find((c) =>
		Object.values(STATES_BY_CATEGORIES[c]).includes(slug),
	);

	return (STATES_BY_CATEGORIES[categoryKey] || {}).name || null;
};

export const getStateInfo = (info) => {
	const { state } = info ?? {};

	if (!state) {
		return { isVehicleAllowed: false, message: 'Vehicle not found' };
	}

	if (allowedStates.includes(state.slug)) {
		return { isVehicleAllowed: true, message: null };
	}

	if (noLongerAvailableCategories.includes(state.category)) {
		return { isVehicleAllowed: false, message: 'Vehicle no longer available' };
	}

	return { isVehicleAllowed: false, message: 'Vehicle not found' };
};

export const convertVehicleStatusSlugToVehicleStatusName = (slug = '') =>
	capitaliseSentence(slug).split('_').join(' ').trim();

export const getVehicleStatusFromVehicleState = ({ category, slug }) => {
	if (noLongerForSaleStates.includes(slug)) {
		return MOTORWAY_PRO_CATEGORY.NO_LONGER_FOR_SALE;
	}

	if (slug === FOR_SALE_CATEGORY.READY_FOR_SALE) {
		return FOR_SALE_CATEGORY.READY_FOR_SALE;
	}

	if (notYetForSaleVehicleStates.includes(slug)) {
		return MOTORWAY_PRO_CATEGORY.NOT_YET_FOR_SALE;
	}

	if (category === STATE_CATEGORIES.SOLD) {
		return SOLD_CATEGORY.SOLD;
	}

	if (slug === FOR_SALE_CATEGORY.SALE_ACTIVE) {
		return FOR_SALE_CATEGORY.FOR_SALE;
	}

	return slug;
};

export const getTooltipTextFromVehicleStatus = (status) => {
	if (status === MOTORWAY_PRO_CATEGORY.NO_LONGER_FOR_SALE) {
		return 'no_longer_available';
	}

	if (status === UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID) {
		return FOR_SALE_CATEGORY.UNDER_OFFER;
	}

	if (status === FOR_SALE_CATEGORY.READY_FOR_SALE) {
		return LocalT.translate('vehicleList.vehicleTooltipStatus.readyForSale');
	}

	if (status === UNSOLD_CATEGORY.UNSOLD_NO_BIDS) {
		return LocalT.translate('vehicleList.vehicleTooltipStatus.unsoldNoBids');
	}

	return status;
};
