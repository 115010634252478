import type { User } from 'Types/user';

export type UserIdentityForTracking = Pick<User, 'dealerId' | 'id'> & {
	dealerStatus: string;
	features: string;
	loginSessionTag: string;
};

const getUserIdentityForTracking = (user: User): UserIdentityForTracking => {
	// We want to limit the information we track about users, to comply with GDPR
	const { dealerId, dealerStatus, features, id, refreshToken } = user || {};

	// refreshToken is generated for each login randomly, so it's unique identifier for login action, but it's dangerous
	// to expose that token, so we are using only half of that token.
	const loginSessionTag = refreshToken?.substring(0, Math.trunc((refreshToken?.length || 0) / 2));
	return {
		dealerId,
		dealerStatus: JSON.stringify(dealerStatus),
		features: JSON.stringify(features),
		id,
		loginSessionTag,
	};
};

export default getUserIdentityForTracking;
