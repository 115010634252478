export const LISTINGS_TABLE_SALE_TYPES = {
	buyItNow: 'BuyItNow',
	liveSale: 'LiveSale',
} as const;

export const LISTINGS_TABLE_ITEM_MOCK = {
	data: {
		expiryTimeUTC: '2025-02-10T12:12:09.419Z',
		originalPrice: 463,
	},
	PK: `type#${LISTINGS_TABLE_SALE_TYPES.buyItNow}`,
	SK: 'listingId#123',
};
