import type { Request, Server } from 'miragejs';

import type { AppSchema, GetCountProps } from 'Mirage/types';
import {
	matchCancelled,
	matchesListType,
	matchesMileageRange,
	matchesPriceRange,
	matchesSold,
	matchFuelType,
	matchUnderOffer,
	matchVehicleGrade,
	resolve,
} from 'Mirage/utilities/filterResolvers';
import { API_ROUTES } from 'Utilities/routes';

export const makeModelCount = (server: Server): void => {
	server.get(`${API_ROUTES.MAKE_MODEL_COUNT}`, (schema: AppSchema, request: Request) => {
		const vehicles = schema.where('vehicle', (vehicle) =>
			resolve(
				matchesSold,
				matchUnderOffer,
				matchCancelled,
				matchesListType,
				matchesMileageRange,
				matchesPriceRange,
				matchFuelType,
				matchVehicleGrade,
			)({ request, vehicle }),
		).models;

		const getCount = ({ config, id }: GetCountProps) => ({ ...config, [id]: (config?.[id] || 0) + 1 });

		const count = vehicles.reduce(
			(countAcc, vehicleCur) => {
				const models = getCount({ config: countAcc?.models, id: vehicleCur?.makeModel?.id });
				const makes = getCount({ config: countAcc?.makes, id: vehicleCur?.makeModel?.makeId });
				return { makes, models };
			},
			{ makes: {}, models: {} },
		);

		return count;
	});
};
