export default {
	cancelled_dealer_withdrew_bid: {
		category: 'cancelled',
		id: 59,
		name: 'Cancelled - Dealer withdrew bid',
		slug: 'cancelled_dealer_withdrew_bid',
	},
	cancelled_not_as_described: {
		category: 'cancelled',
		id: 54,
		name: 'Cancelled - Not as described',
		slug: 'cancelled_not_as_described',
	},
	cancelled_not_collected_in_time: {
		category: 'cancelled',
		id: 58,
		name: 'Cancelled - Not collected in time',
		slug: 'cancelled_not_collected_in_time',
	},
	cancelled_other_dealer_issue: {
		category: 'cancelled',
		id: 60,
		name: 'Cancelled - Other dealer issue',
		slug: 'cancelled_other_dealer_issue',
	},
	cancelled_other_seller_issue: {
		category: 'cancelled',
		id: 57,
		name: 'Cancelled - Other seller issue',
		slug: 'cancelled_other_seller_issue',
	},
	cancelled_seller_uncontactable: {
		category: 'cancelled',
		id: 55,
		name: 'Cancelled - Seller uncontactable',
		slug: 'cancelled_seller_uncontactable',
	},
	cancelled_sold_elsewhere: {
		category: 'cancelled',
		id: 56,
		name: 'Cancelled - Sold elsewhere',
		slug: 'cancelled_sold_elsewhere',
	},
	car_gone: {
		category: 'rejected',
		id: 36,
		name: 'Rejected - Car sold elsewhere',
		slug: 'car_gone',
	},
	customer_keep_car: {
		category: 'rejected',
		id: 29,
		name: 'Rejected - Seller keeping car',
		slug: 'customer_keep_car',
	},
	customer_lost: {
		category: 'rejected',
		id: 40,
		name: 'Rejected - Lost contact with seller',
		slug: 'customer_lost',
	},
	duplicated: {
		category: 'junk',
		id: 47,
		name: 'Duplicate lead',
		slug: 'duplicated',
	},
	for_sale: {
		category: 'for_sale',
		id: 30,
		name: 'For sale',
		slug: 'for_sale',
	},
	full_details: {
		category: 'profiled',
		id: 9,
		name: 'Full details',
		slug: 'full_details',
	},
	full_details_contacted: {
		category: 'profiled',
		id: 39,
		name: 'Full details - Seller contacted',
		slug: 'full_details_contacted',
	},
	full_details_wait: {
		category: 'profiled',
		id: 44,
		name: 'Full details - Awaiting photos',
		slug: 'full_details_wait',
	},
	manual_quote: {
		category: 'quoted',
		id: 35,
		name: 'Manual quote',
		slug: 'manual_quote',
	},
	part_details: {
		category: 'profiled',
		id: 25,
		name: 'Part details',
		slug: 'part_details',
	},
	part_details_contacted: {
		category: 'profiled',
		id: 32,
		name: 'Part details - Seller contacted',
		slug: 'part_details_contacted',
	},
	qa_failed: {
		category: 'profiled',
		id: 89,
		name: 'QA - Failed',
		slug: 'qa_failed',
	},
	qa_on_hold: {
		category: 'profiled',
		id: 86,
		name: 'QA - On hold',
		slug: 'qa_on_hold',
	},
	qa_ready: {
		category: 'profiled',
		id: 88,
		name: 'QA - Ready',
		slug: 'qa_ready',
	},
	quote_accepted: {
		category: 'quoted',
		id: 6,
		name: 'Quote accepted',
		slug: 'quote_accepted',
	},
	quoted: {
		category: 'quoted',
		id: 4,
		name: 'Quoted',
		slug: 'quoted',
	},
	quoted_seller_contacted: {
		category: 'quoted',
		id: 53,
		name: 'Quoted - Seller contacted',
		slug: 'quoted_seller_contacted',
	},
	ready_for_sale: {
		category: 'for_sale',
		id: 51,
		name: 'Ready for sale',
		slug: 'ready_for_sale',
	},
	sale_active: {
		category: 'for_sale',
		id: 87,
		name: 'For sale - Sale active',
		slug: 'sale_active',
	},
	sold: {
		category: 'sold',
		id: 41,
		name: 'Sold',
		slug: 'sold',
	},
	sold_confirmed: {
		category: 'sold',
		id: 50,
		name: 'Sold - Seller confirmed',
		slug: 'sold_confirmed',
	},
	sold_documents_reviewed: {
		category: 'sold',
		id: 93,
		name: 'Sold - Documents reviewed',
		slug: 'sold_documents_reviewed',
	},
	sold_invoice_paid: {
		category: 'sold',
		id: 22,
		name: 'Sold - Invoice paid',
		slug: 'sold_invoice_paid',
	},
	sold_invoice_raised: {
		category: 'sold',
		id: 42,
		name: 'Sold - Invoice raised',
		slug: 'sold_invoice_raised',
	},
	sold_purchase_confirmed: {
		category: 'sold',
		id: 91,
		name: 'Sold - Purchase confirmed',
		slug: 'sold_purchase_confirmed',
	},
	sold_wait: {
		category: 'sold',
		id: 49,
		name: 'Sold - Awaiting confirmation',
		slug: 'sold_wait',
	},
	test: {
		category: 'junk',
		id: 24,
		name: 'Test run',
		slug: 'test',
	},
	too_expensive: {
		category: 'rejected',
		id: 26,
		name: 'Rejected - Price too high',
		slug: 'too_expensive',
	},
	under_offer: {
		category: 'for_sale',
		id: 48,
		name: 'Under offer',
		slug: 'under_offer',
	},
	unsellable: {
		category: 'rejected',
		id: 23,
		name: 'Rejected - Unsellable',
		slug: 'unsellable',
	},
	unsold_below_reserve_bid: {
		category: 'unsold',
		id: 90,
		name: 'Unsold - Below reserve bid',
		slug: 'unsold_below_reserve_bid',
	},
	unsold_no_bids: {
		category: 'unsold',
		id: 89,
		name: 'Unsold - No bids',
		slug: 'unsold_no_bids',
	},
	unworked: {
		category: 'junk',
		id: 38,
		name: 'Un-worked',
		slug: 'unworked',
	},
	write_off: {
		category: 'rejected',
		id: 52,
		name: 'Rejected - Write-off',
		slug: 'write_off',
	},
	wrong_number: {
		category: 'junk',
		id: 27,
		name: 'Wrong number',
		slug: 'wrong_number',
	},
};
