import financeStatusResponse from 'Fixtures/payments/commonFixtures/financeStatus.json';
import type { MirageServer } from 'Mirage/types';
import { API_ROUTES } from 'Utilities/routes';

const initFinanceStatusRoutes = (server: MirageServer): void => {
	server.get(`${API_ROUTES.FINANCE_STATUS}`, () => {
		return financeStatusResponse;
	});
};

export default initFinanceStatusRoutes;
