import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { DRAG } = SNOWPLOW_EVENTS;

export const triggerUserDraggedOnMapEvent = (mapType: 'static_map' | 'dynamic_map'): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				customData: { label: mapType },
				name: `dlr_location_map_dragged`,
				schemaName: DRAG.SCHEMA,
				version: DRAG.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
