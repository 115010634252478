import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerVrmCopiedEventParams {
	category: string;
}

export const triggerVrmCopiedEvent = ({ category }: TriggerVrmCopiedEventParams): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category,
				name: `dlr_vrm_copied`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
